import styled from "@emotion/styled";
import { Switch } from "@mui/material";

export const MaterialUISwitch = styled(Switch)(() => ({
  width: 34,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    marginTop: 4,
    transitionDuration: "300ms",
    color: "#BCBDC1",
    "&.Mui-checked": {
      transform: "translateX(10px)",
      color: "#fff",

      "& + .MuiSwitch-track": {
        backgroundColor: "#0E2EE3",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#111",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    marginLeft: 4,
    borderRadius: '4px',
  },
  "& .MuiSwitch-track": {
    borderRadius: '8px',
    backgroundColor: "#fff",
    border: "1px solid #BCBDC1",
    opacity: 1,
  },
}));