import { Back, Plus } from "../svgIcons";
import RightTextButton from "./rightTextButton";
import { FunctionComponent } from "react";

interface ModalHeaderProps {
  title: string;
  name?: string;
  buttonName: string;
  buttonNameTwo?: string;
  onClickButton: () => void;
  onClickButtonTwo?: () => void;
}
const ModalHeader: FunctionComponent<ModalHeaderProps> = (props) => {
  return (
    <div className="w-full flex flex-col lg:flex-row justify-start lg:items-center gap-2 bg-white">
      <div className="flex gap-2 justify-start items-center">
        <RightTextButton
          name={props.buttonName}
          icon={<Back />}
          bgColor="bg-darkBlue"
          onClick={props.onClickButton}
        />
        {props?.buttonNameTwo && (
          <RightTextButton
            name={props.buttonNameTwo}
            icon={<Plus />}
            onClick={props?.onClickButtonTwo}
          />
        )}
      </div>
      <div className="flex flex-col justify-center items-start">
        {props?.name && (
          <span className="text-grayBg text-xl lg:text-2xl font-normal lg:font-medium">
            {props.name}
          </span>
        )}
        <span className="text-text text-xl lg:text-2xl font-normal lg:font-medium">
          {props.title}
        </span>
      </div>
    </div>
  );
};
export default ModalHeader;
