import { Skeleton } from "@mui/material";

const DoughnutChartSkeleton = () => {
  return (
    <div className="w-full flex flex-col justify-start items-end gap-2">
      <Skeleton variant="rounded" width={34} height={34} />
      <Skeleton variant="circular" width={291} height={291} />
      <Skeleton variant="rounded" width="100%" height={34} />
      <Skeleton variant="rounded" width="100%" height={34} />
      <Skeleton variant="rounded" width="100%" height={34} />
    </div>
  );
};
export default DoughnutChartSkeleton;
