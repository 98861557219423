import { Skeleton } from "@mui/material";

const AccountCardSkeleton = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-2">
      <Skeleton variant="rectangular" width={332} height={492} />
      <div className="w-full flex justify-between gap-2">
        <Skeleton variant="rounded" width="100%" height={44} />
        <Skeleton variant="rounded" width="100%" height={44} />
        <Skeleton variant="rounded" width="100%" height={44} />
      </div>
    </div>
  );
};
export default AccountCardSkeleton;
