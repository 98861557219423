import {
  CorrectionMark,
} from "../svgIcons";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import RightTextButton from "./rightTextButton";
import PasswordComponent from "./passwordComponent";
import PasswordCheckComponet from "./passwordCheckComponent";
import { passwordAnalyze } from "../../../functions/passwordAnalyze";

interface IFormInputs {
  password: string;
  newPassword: string;
}

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordRules, setPasswordRules] = useState({
    digits: false,
    capital: false,
    number: false,
    lowercase: false,
    special: false,
  });
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInputs>({});

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChangePassword: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const passwordValue = event.target.value;
    const analyze = passwordAnalyze(passwordRules, passwordValue);
    if (analyze) {
      clearErrors("password");
      // setValue("password", passwordValue);
    } else {
      setError("password", {
        type: "manual",
        message: "Password is not completed",
      });
    }
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {};

  return (
    <form
      className="flex flex-col gap-6 justify-center items-end py-3"
      onSubmit={handleSubmit(onSubmit)}
    >
      <PasswordComponent
        title="Current Password"
        handleChange={handleChangePassword}
        error={errors.password ? true : false}
        widthFull={true}
      />

      <PasswordComponent
        handleChange={handleChangePassword}
        error={errors.password ? true : false}
        widthFull={true}
      />
      <PasswordCheckComponet passwordRules={passwordRules} />
      <PasswordComponent
        title="Confirm Password"
        handleChange={handleChangePassword}
        error={errors.password ? true : false}
        widthFull={true}
      />
      <PasswordCheckComponet passwordRules={passwordRules} />

      <RightTextButton
        name="Change Password"
        icon={<CorrectionMark />}
        className="my-3"
        onClick={() => !errors.password}
      />
    </form>
  );
};
export default ChangePassword;
