import { FunctionComponent } from "react";
import Icon from "../icon";
import { ThreeDots } from "react-loader-spinner";

interface ChildProps {
  name?: string;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  icon?: JSX.Element;
}

const BorderedButton: FunctionComponent<ChildProps> = (props) => {
  return (
    <div
      className={`rounded-md flex justify-center items-center gap-2 p-2 cursor-pointer bg-white hover:bg-veryLightGray active:bg-lightGray border border-solid border-borders/60 ${props.className}`}
      onClick={props.onClick}
    >
      {props.loading ? (
        <ThreeDots
          height="30"
          width="60"
          radius="9"
          color="#FFFFFF"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
        />
      ) : (
        <div className="flex justify-center items-center gap-2">
          {props?.icon && <Icon icon={props.icon} size={24} />}
          {props?.name && <span className="text-primary text-sm font-normal">{props.name}</span>}
        </div>
      )}
    </div>
  );
};

export default BorderedButton;
