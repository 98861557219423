import { FC, useState } from "react";
import BorderedButton from "../../components/common/basics/borderedButton";
import RightTextButton from "../../components/common/basics/rightTextButton";
import { CorrectionMark } from "../../components/common/svgIcons";
import StepperComponent from "../../components/common/basics/stepperComponent";
import CryptoStartForm from "./cryptoStartForm";
import CryptoConfirmForm from "./cryptoConfirmForm";
import CryptoTransfer from "./cryptoTransfer";
import CryptoFinalize from "./cryptoFinalize";
import { steps } from "../../constants/cryptoSteps";
import { IFormInputs } from "./walletBaseComponent";
import { UseFormRegister } from "react-hook-form";

interface ChildComponentProps {
  register: UseFormRegister<IFormInputs>;
}

const CryptoPayDepositFormComponent: FC<ChildComponentProps> = ({
  register,
}) => {
  
  const [activeStep, setActiveStep] = useState(0);
  const buttonNames = ["Next Step", "Start Payment", "Complete"];
  const handelNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <div className="flex flex-col gap-6">
      <StepperComponent activeStep={activeStep} steps={steps} />
      <span className="text-text text-base font-medium">
        CryptoCurrency Payment Processor
      </span>
      {activeStep < 2 && (
        <span className="text-text text-base font-normal">
          "Fill the form below and click on the Next button."
        </span>
      )}
      {activeStep === 2 && (
        <span className="text-error text-base font-normal">
          "Once you have completed the transfer to our wallet, click the
          Completed button."
        </span>
      )}

      {activeStep === 3 && (
        <span className="text-primary text-base font-normal">
          "If you made payment successfully, wait for the system confirm alert."
        </span>
      )}
      {activeStep === 0 && <CryptoStartForm />}
      {activeStep === 1 && <CryptoConfirmForm />}
      {activeStep === 2 && <CryptoTransfer />}
      {activeStep === 3 && <CryptoFinalize />}
      {activeStep < 3 && <hr className="w-full bg-borders/50 h-0.5" />}
      <div className="flex gap-2 p-4 w-full md:w-72">
        {activeStep === 1 && (
          <BorderedButton
            name="Back"
            onClick={() => setActiveStep(activeStep - 1)}
          />
        )}
        {activeStep < 3 && (
          <RightTextButton
            name={buttonNames[activeStep]}
            icon={<CorrectionMark />}
            onClick={handelNextStep}
          />
        )}
      </div>
    </div>
  );
};
export default CryptoPayDepositFormComponent;
