import { FilterIcon } from "../../components/common/svgIcons";
import IbLayout from "../../components/layouts/ibLayout";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import TableComponent from "../account/tableComponent";
import { accountStatementsTableKeys } from "../../constants/ibTablesData";
import DatePickerComponent from "../../components/common/basics/datePickerComponent";
import { CalendarIcon } from "@mui/x-date-pickers";
import { useState } from "react";
import IbPagesInnerLayout from "../../components/layouts/ibPagesInnerLayout ";

const IbAccountStatements = () => {
  const [openFilterNav, setOpenFilterNav] = useState<boolean>(true);

  return (
    <IbPagesInnerLayout
      buttonName="Export Account"
      handleClickButton={() => {}}
      handleSwitchFilters={() => setOpenFilterNav(!openFilterNav)}
    >
      <div className="w-full flex flex-col justify-start items-start gap-6">
        <IbLayout
          title="Accounts Statement"
          smallTitle="Acc. Statement"
          openFilterNav={openFilterNav}
          largeFilter={true}
        >
          <div className="w-full lg:w-auto grid grid-cols-2 md:flex md:flex-row gap-2 justify-center items-center">
            <DatePickerComponent
              name="date"
              icon={<CalendarIcon />}
              handleChange={() => {}}
              type="text"
            />
            <DropDownComponent
              name="columns"
              selectList={testList}
              handleSelect={() => {}}
              placeHolder="IB"
              // widthFull
              width="w-full lg:w-[150px]"
            />

            <DropDownComponent
              name="columns"
              selectList={testList}
              handleSelect={() => {}}
              placeHolder="Select Category"
              className="col-span-2"
              // widthFull
              width="w-full lg:w-[150px]"
            />
            <DropDownComponent
              name="columns"
              icon={<FilterIcon className="text-text" />}
              selectList={testList}
              handleSelect={() => {}}
              placeHolder="All Columns Selected"
              className="col-span-2"
              // widthFull
              width="w-full lg:w-[150px]"
            />
          </div>
        </IbLayout>
        <div className="w-full overflow-x-auto sc-sp2 flex flex-col justify-start items-start">
          <TableComponent keys={accountStatementsTableKeys} />
        </div>
      </div>
    </IbPagesInnerLayout>
  );
};

export default IbAccountStatements;
