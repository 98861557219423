import { FunctionComponent } from "react";
import RightTextButton from "../common/basics/rightTextButton";

interface PagesInnerLayoutProps {
  children?: React.ReactNode;
  title?: string;
  buttonName?: string;
  buttonNameSecond?: string;
  buttonIcon?: JSX.Element;
  buttonIconSecond?: JSX.Element;
  buttonIconSecondDouble?: JSX.Element;
  handleClickButton?: () => void;
  handleClickButtonSecond?: () => void;
}

const PagesInnerLayout: FunctionComponent<PagesInnerLayoutProps> = (props) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <div className="w-full lg:max-w-fit flex flex-col gap-2">
        <div className="flex flex-col-reverse md:flex-row lg:justify-between items-end gap-8 lg:gap-20">
          {props.title && (
           <div className="w-full ">
             <span className="text-primary text-xl font-medium">
              {props.title}
            </span>
           </div>
          )}
          {(props.buttonName || props.buttonIcon) && <div className="flex items-center gap-2">
            {(props?.buttonNameSecond || props?.handleClickButtonSecond) && (
              <RightTextButton
                widthFull={false}
                name={props.buttonNameSecond}
                onClick={props.handleClickButtonSecond}
                icon={props.buttonIconSecond}
                secondIcon={props?.buttonIconSecondDouble}
                bgColor="bg-darkBlue"
              />
            )}
            {props?.buttonName && (
              <RightTextButton
                widthFull={false}
                name={props.buttonName}
                onClick={props.handleClickButton}
                icon={props.buttonIcon}
              />
            )}
          </div>}
        </div>
        <div className="w-full pb-28 lg:pb-16">{props?.children}</div>
      </div>
    </div>
  );
};
export default PagesInnerLayout;
