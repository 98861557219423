import { useState } from "react";
import SignupForm from "./signupForm";
import LoginForm from "./loginForm";
import TabsComponent from "../../components/common/basics/tabsComponent";

const SignupLoginTabs = () => {
  const [selectedtabName, setSelectedselected] = useState<string>("Create a new Account");

  return (
    <div className="w-full md:max-w-fit flex flex-col gap-2.5 justify-center items-center p-6 border border-borders/50 rounded-md">
      <TabsComponent
          tabList={["Create a new Account", "Log in to your Account"]}
          onClickTab={(name) => setSelectedselected(name)}
          selected={selectedtabName}
        />
      {selectedtabName === "Create a new Account" && <SignupForm />}
      {selectedtabName === "Log in to your Account" && <LoginForm />}
    </div>
  );
};

export default SignupLoginTabs;
