import {
  Accounts,
  ClientsIcon,
  Dashboard,
  DemoAccounts,
  Deposit,
  DiagramIcon,
  FinancialStatementIcon,
  FlowIcon,
  HandshakeIcon,
  LiveAccounts,
  LoudSpeakerIcon,
  MagnetIcon,
  ReportIcon,
  Transfer,
  Verification,
  Wallet,
  Withdraw,
} from "../components/common/svgIcons";

export const MenuItems = [
  {
    name: "Dashboard",
    icon: <Dashboard />,
    address: "/dashboard",
  },
  {
    name: "My Accounts",
    icon: <Accounts />,
    items: [
      {
        name: "Live Accounts",
        icon: <LiveAccounts />,
        address: "/live-accounts",
      },
      {
        name: "Demo Accounts",
        icon: <DemoAccounts />,
        address: "/demo-accounts",
      },
    ],
  },
  {
    name: "Wallet",
    icon: <Wallet />,
    address: "/wallet",
  },
  {
    name: "Verification",
    icon: <Verification />,
    address: "/verification",
  },
  {
    name: "IB",
    icon: <HandshakeIcon />,
    items: [
      {
        name: "Clients",
        icon: <ClientsIcon />,
        address: "/ib-clients",
      },
      {
        name: "Leads",
        icon: <MagnetIcon />,
        address: "/ib-leads",
      },
      {
        name: "Account Statement",
        icon: <FinancialStatementIcon />,
        address: "/ib-account-statement",
      },
      {
        name: "Sub ID Statement",
        icon: <FinancialStatementIcon />,
        address: "/ib-sub-ib-statement",
      },
      {
        name: "CPA Report",
        icon: <ReportIcon />,
        address: "/ib-cpa-report",
      },
      {
        name: "Breakdown Report",
        icon: <DiagramIcon />,
        address: "/ib-breakdown-report",
      },
      {
        name: "IB Diagram",
        icon: <FlowIcon />,
        address: "/ib-diagram",
      },
      {
        name: "IB Promotion",
        icon: <LoudSpeakerIcon />,
        address: "/ib-promotion",
      },
    ],
  },
];
