import {
  BusinessIcon,
  CorrectionMark,
  GlobeIcon,
  Hashtag,
  UserIcon,
} from "../../components/common/svgIcons";
import { FunctionComponent, useState } from "react";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import InputComponent from "../../components/common/basics/inputComponent";
import RightTextButton from "../../components/common/basics/rightTextButton";
import NoteComponent from "../../components/common/basics/noteComponent";
import BorderedButton from "../../components/common/basics/borderedButton";

interface AddressFormPropsInterface{
    handelNextStep: () => void;
    handelLastStep: () => void;
}

const AddressForm: FunctionComponent<AddressFormPropsInterface> = ({handelNextStep, handelLastStep}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-6">
      <NoteComponent
        title="St. Address 1"
        name="address_one"
        icon={<BusinessIcon />}
        handleChange={() => {}}
      />
      <NoteComponent
        title="St. Address 2"
        name="address_two"
        icon={<BusinessIcon />}
        handleChange={() => {}}
      />
      <InputComponent
        title="City"
        name="city"
        icon={<UserIcon />}
        handleChange={() => {}}
        type="text"
      />
      <InputComponent
        title="State / Province"
        name="province"
        icon={<UserIcon />}
        handleChange={() => {}}
        type="text"
      />
      <InputComponent
        title="Postal Code"
        name="postal_code"
        icon={<Hashtag />}
        handleChange={() => {}}
        type="text"
      />
      <DropDownComponent
        title="Country of Residence"
        name="country"
        icon={<GlobeIcon />}
        selectList={testList}
        handleSelect={() => {}}
        widthFull

      />
      <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
      <div className="flex gap-2 p-4 w-full md:w-72">
      <BorderedButton name="back" className="py-2 px-2" onClick={handelLastStep} />
        <RightTextButton
          name="save"
          icon={<CorrectionMark />}
          onClick={handelNextStep}
        />
      </div>
    </div>
  );
};
export default AddressForm;
