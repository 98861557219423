import {
  CorrectionMark,
  Dollar,
  Note,
  Wallet,
} from "../../components/common/svgIcons";
import BorderedButton from "../../components/common/basics/borderedButton";
import SubmitButton from "../../components/common/basics/submitButton";
import { useState } from "react";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import InputComponent from "../../components/common/basics/inputComponent";
import NoteComponent from "../../components/common/basics/noteComponent";

const CryptoPayWithdrawComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-6">
      <DropDownComponent
        name="currency"
        title="Choose Currency"
        icon={<Dollar className="text-darkBlue" />}
        selectList={testList}
        handleSelect={(value) => {}}
        required
        // register={register}
        // error={errors.currency ? true : false}
        widthFull
      />
      <InputComponent
        name="amount"
        title="Amount in USDTERC20"
        icon={<Dollar className="text-darkBlue" />}
        type="text"
        // handleChange={(value) => setValue("amount", value)}
        // register={register}
        required
        // error={errors.amount ? true : false}
        widthFull={true}
      />
      <div className="w-full col-span-2 text-left py-4">
        <span className="text-primary text-base font-normal">
          Current Balance: $900
        </span>
      </div>
      <NoteComponent
        name="wallet_address"
        title="Wallet Address"
        icon={<Wallet className="text-darkBlue" />}
        // handleChange={(value) => setValue("wallet_address", value)}
        // register={register}
        required
        // error={errors.wallet_address ? true : false}
        widthFull
      />
      <NoteComponent
        name="note"
        title="Note"
        icon={<Note className="text-darkBlue" />}
        // handleChange={(value) => setValue("note", value)}
        // register={register}
        required
        // error={errors.note ? true : false}
        widthFull
      />
      <hr className="w-full col-span-2 bg-borders/50 flex-grow h-0.5" />
      <div className="flex gap-2 p-4 w-full md:w-72">
        <BorderedButton name="Cancel" />
        <SubmitButton
          widthFull={true}
          loading={loading}
          name="Submit Withdraw"
          icon={<CorrectionMark />}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};
export default CryptoPayWithdrawComponent;
