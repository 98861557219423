import Icon from "../../components/common/icon";
import {
  CoppyIcon,
  LinkIcon,
} from "../../components/common/svgIcons";
import PagesInnerLayout from "../../components/layouts/pagesInnerLayout";

function IbPromotion() {

  return (
    <PagesInnerLayout title="IB Promotion">
      <div className="h-full flex flex-col justify-start items-center gap-6 p-6 rounded-md border border-borders/50 ">
        <Icon icon={<LinkIcon />} size={64} />
        <span className="text-base font-medium text-text">
          Test without image:
        </span>
        <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
        <div className="flex gap-4 justify-center items-center">
          <div className="rounded-md text-center p-2 bg-primary">
            <Icon icon={<CoppyIcon color="white" />} size={17.5} />
          </div>
          <span className="text-primary text-base font-semibold ">
            TCRg6RZQZYghnDFuvF×KVHKAv5ZBLQMFxs
          </span>
        </div>
        <div className="flex gap-4 justify-center items-center">
          <div className="rounded-md text-center p-2 bg-primary">
            <Icon icon={<CoppyIcon color="white" />} size={17.5} />
          </div>
          <span className="text-primary text-base font-semibold ">
            TCRg6RZQZYghnDFuvF×KVHKAv5ZBLQMFxs
          </span>
        </div>
        <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
        <span className="text-base font-normal text-text">Description</span>
      </div>
    </PagesInnerLayout>
  );
}

export default IbPromotion;
