import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import PasswordVerification from "./pages/passwordVerification";
import AuthenticationPageLayout from "./components/layouts/authenticationPageLayout";
import SignupLoginTabs from "./pages/loginSignup/signupLoginTabs";
import Dashboard from "./pages/dashboard";
import DemoAccounts from "./pages/accounts/DemoAccounts";
import LiveAccounts from "./pages/accounts/LiveAccounts";
import Account from "./pages/account";
import BaseLayout from "./components/layouts/baseLayout";
import Wallet from "./pages/wallet";
import Verification from "./pages/verification";
import Profile from "./pages/profile";
import NotFound from "./pages/notFound";
import IbClients from "./pages/ib/ibClients";
import IbLeads from "./pages/ib/ibLeads";
import IbAccountStatements from "./pages/ib/ibAccountStatements";
import IbCpaReports from "./pages/ib/ibCpaReports";
import IbSubIbAccount from "./pages/ib/ibSubIbAccount";
import IbBreakDownReport from "./pages/ib/ibBreakDownReport";
import IbDiagram from "./pages/ib/ibDiagram";
import IbPromotion from "./pages/ib/ibPromotion";

function Routes() {
  const location = useLocation();
  const history = useHistory();

  // useEffect(() => {
  //   return history.listen((location) => {
  //     console.log("Exact Path:", location.pathname);
  //   });
  // }, [history]);

  // useEffect(() => {
  //   console.log("Exact Path:", location.pathname);
  // }, [location]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={["/", "/password-verification"]}>
          <AuthenticationPageLayout>
            <Switch>
              <Route exact path="/" component={SignupLoginTabs} />
              <Route
                exact
                path="/password-verification"
                component={PasswordVerification}
              />
            </Switch>
          </AuthenticationPageLayout>
        </Route>
        <Route
          exact
          path={[
            "/dashboard",
            "/demo-accounts",
            "/live-accounts",
            "/verification",
            "/wallet",
            "/profile",
            "/ib-clients",
            "/ib-leads",
            "/ib-account-statement",
            "/ib-sub-ib-statement",
            "/ib-cpa-report",
            "/ib-breakdown-report",
            "/ib-diagram",
            "/ib-promotion",
          ]}
        >
          <BaseLayout title={"title"} withNav={true}>
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/demo-accounts" component={DemoAccounts} />
              <Route exact path="/live-accounts" component={LiveAccounts} />
              <Route exact path="/verification" component={Verification} />
              <Route exact path="/wallet" component={Wallet} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/ib-clients" component={IbClients} />
              <Route exact path="/ib-leads" component={IbLeads} />
              <Route exact path="/ib-account-statement" component={IbAccountStatements} />
              <Route exact path="/ib-sub-ib-statement" component={IbSubIbAccount} />
              <Route exact path="/ib-cpa-report" component={IbCpaReports} />
              <Route exact path="/ib-breakdown-report" component={IbBreakDownReport} />
              <Route exact path="/ib-diagram" component={IbDiagram} />
              <Route exact path="/ib-promotion" component={IbPromotion} />
            </Switch>
          </BaseLayout>
        </Route>
        <Route exact path="/account/:id">
          <BaseLayout title={"title"} withNav={false}>
            <Switch>
              <Route exact path="/account/:id" component={Account} />
            </Switch>
          </BaseLayout>
        </Route>
        <Route component={NotFound} /> {/* 404 Page */}
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
