import { FormControlLabel, Typography } from "@mui/material";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { UserIcon } from "../../components/common/svgIcons";
import PagesInnerLayout from "../../components/layouts/pagesInnerLayout";
import { testList } from "../../constants/testList";
import { MaterialUISwitch } from "../../constants/materialUI";
import VolumeAllocationComponent from "./volumeAllocationComponent";
import { useState } from "react";
import BestWorstTradeComponent from "./bestWorstTradeComponent";
import DepositWithdrawalComponent from "./depositWithdrawalComponent";
import GainComponent from "./gainComponent";
import ROIComponent from "./roiComponent";
import { title } from "process";

const Dashboard = () => {
  const [openTabs, setOpenTabs] = useState<string[]>([""]);

  const HandleAddOrRemove = (name: string) => {
    if (openTabs.includes(name)) {
      setOpenTabs(openTabs.filter((i) => i !== name));
    } else {
      setOpenTabs([...openTabs, name]);
    }
  };

  const dashboardList = [
    {
      title: "Volume Allocation",
      name: "volume_allocation",
    },
    {
      title: "Best / Worst Trade",
      name: "best_worst_trade",
    },
    {
      title: "Deposit & Withdrawals",
      name: "deposit_withdrawals",
    },
    {
      title: "Gain",
      name: "gain",
    },
    {
      title: "ROI",
      name: "roi",
    },
  ];
  return (
    <PagesInnerLayout title="Dashboard">
      <div className="flex flex-col justify-start items-start gap-8">
        <div className="w-full lg:w-auto flex flex-col justify-start items-center md:items-start gap-6 p-6 border border-borders/50 rounded-md ">
          <DropDownComponent
            title="Select Account View Details"
            name="account_view_detail"
            icon={<UserIcon />}
            placeHolder="select"
            selectList={testList}
            handleSelect={() => {}}
            width="w-full md:max-w-[294px]"
          />
          <div className="grid grid-cols-2 lg:grid-cols-5  gap-6">
            {dashboardList.map((dashboardItem) => (
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    checked={openTabs.includes(dashboardItem.name)}
                    onChange={() => HandleAddOrRemove(dashboardItem.name)}
                  />
                }
                label={<Typography noWrap>{dashboardItem.title}</Typography>}
              />
            ))}
            
          
          </div>
        </div>
        <div className="w-full overflow-x-auto flex flex-col gap-4 lg:flex-row justify-start items-start">
          <div className="w-full lg:w-auto flex flex-col lg:flex-row justify-start items-start gap-4">
            {openTabs.includes("volume_allocation") && (
              <VolumeAllocationComponent />
            )}
            {openTabs.includes("best_worst_trade") && (
              <BestWorstTradeComponent />
            )}
          </div>
          <div className="w-full lg:w-auto flex flex-col lg:flex-row justify-start items-start gap-4">
            {openTabs.includes("deposit_withdrawals") && (
              <DepositWithdrawalComponent />
            )}
          </div>
          <div className="flex flex-row lg:flex-col justify-start items-start gap-6">
            {openTabs.includes("gain") && <GainComponent />}
            {openTabs.includes("roi") && <ROIComponent />}
          </div>
        </div>
      </div>
    </PagesInnerLayout>
  );
};

export default Dashboard;
