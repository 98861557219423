import {
  ConfirmIcon,
  FinalizeIcon,
  FlagIcon,
  TransferIcon,
} from "../components/common/svgIcons";
import { verificationStepperInterface } from "./verificationSteppers";

export const steps: verificationStepperInterface[] = [
  {
    name: "Start",
    icon: <FlagIcon />,
    required: true,
  },
  {
    name: "Confirm",
    icon: <ConfirmIcon />,
    required: true,
  },
  {
    name: "Transfer",
    icon: <TransferIcon />,
    required: true,
  },
  {
    name: "Finalize",
    icon: <FinalizeIcon />,
    required: true,
  },
];
