export const testList = [
    {
        name: "ten",
        value: 10
    },
    {
        name: "Twenty",
        value: 20
    },
    {
        name: "Thirty",
        value: 30
    },
]