import { FilterIcon } from "../../components/common/svgIcons";
import IbLayout from "../../components/layouts/ibLayout";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import TableComponent from "../account/tableComponent";
import { ibSubIbAccountTableKeys } from "../../constants/ibTablesData";
import DatePickerComponent from "../../components/common/basics/datePickerComponent";
import { CalendarIcon } from "@mui/x-date-pickers";
import { useState } from "react";
import IbPagesInnerLayout from "../../components/layouts/ibPagesInnerLayout ";

const IbSubIbAccount = () => {
  const [openFilterNav, setOpenFilterNav] = useState<boolean>(true);

  return (
    <IbPagesInnerLayout
      buttonName="Export Sub IB"
      handleClickButton={() => {}}
      handleSwitchFilters={() => setOpenFilterNav(!openFilterNav)}
    >
      <div className="w-full lg:max-w-fit flex flex-col justify-start items-start gap-6">
        <IbLayout
          title="Sub IB Statement"
          openFilterNav={openFilterNav}
          largeFilter
        >
          <div className="w-full lg:w-auto flex flex-col lg:flex-row gap-2 justify-center items-center">
            <div className="w-full lg:w-auto flex gap-2 justify-center items-center">
              <DatePickerComponent
                name="date"
                icon={<CalendarIcon />}
                handleChange={() => {}}
                type="text"
              />
              <DropDownComponent
                name="columns"
                selectList={testList}
                handleSelect={() => {}}
                placeHolder="Select Category"
                width="w-full lg:w-[135px]"
              />
            </div>
            <div className="w-full flex flex-col md:flex-row lg:w-auto gap-2 justify-center items-center">
              <DropDownComponent
                name="columns"
                selectList={testList}
                handleSelect={() => {}}
                placeHolder="Select Type"
                width="w-full lg:w-[135px]"
              />
              <DropDownComponent
                name="columns"
                icon={<FilterIcon className="text-text" />}
                selectList={testList}
                handleSelect={() => {}}
                placeHolder="All Columns Selected"
                width="w-full lg:w-[135px]"
              />
            </div>
          </div>
        </IbLayout>
        <div className="w-full overflow-x-auto sc-sp2 flex flex-col justify-start items-start">
          <TableComponent keys={ibSubIbAccountTableKeys} />
        </div>
      </div>
    </IbPagesInnerLayout>
  );
};

export default IbSubIbAccount;
