import {
  CorrectionMark,
  GlobeIcon,
} from "../../components/common/svgIcons";
import { FunctionComponent, useState } from "react";
import RightTextButton from "../../components/common/basics/rightTextButton";
import BorderedButton from "../../components/common/basics/borderedButton";
import UploadBox from "../../components/common/basics/uploadBox";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";

interface ProofOfResidencyPropsInterface{
    handelNextStep: () => void;
    handelLastStep: () => void;
}

const ProofOfResidencyForm: FunctionComponent<ProofOfResidencyPropsInterface> = ({handelNextStep, handelLastStep}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-6">
      <DropDownComponent
        title="Proof of Residence Type"
        name="proof_residence_type"
        icon={<GlobeIcon />}
        selectList={testList}
        handleSelect={() => {}}
        widthFull
      />
      <div className="flex col-span-2 flex-col gap-1">
        <span className="text-darkBlue text-sm font-medium">Front Image</span>
        <UploadBox />
        <span className="text-grayBg text-xs font-normal">Max size: 1 Mb</span>
      </div>
      <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
      <div className="flex gap-2 p-4 w-full md:w-72">
      <BorderedButton name="back" className="py-2 px-2" onClick={handelLastStep} />
        <RightTextButton
          name="continue"
          icon={<CorrectionMark />}
          onClick={handelNextStep}
          bgColor="bg-darkBlue"
        />
      </div>
    </div>
  );
};
export default ProofOfResidencyForm;
