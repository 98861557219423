import {
  CalendarIcon,
  CorrectionMark,
  Hashtag,
} from "../../components/common/svgIcons";
import { FunctionComponent, useState } from "react";
import InputComponent from "../../components/common/basics/inputComponent";
import RightTextButton from "../../components/common/basics/rightTextButton";
import BorderedButton from "../../components/common/basics/borderedButton";
import UploadBox from "../../components/common/basics/uploadBox";
import DatePickerComponent from "../../components/common/basics/datePickerComponent";

interface DriversLisencePropsInterface{
    handelNextStep: () => void;
    handelLastStep: () => void;
}

const DriversLisenceForm: FunctionComponent<DriversLisencePropsInterface> = ({handelNextStep, handelLastStep}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-6">
      <InputComponent
        title="Driver's License Number"
        name="driver_license_number"
        icon={<Hashtag />}
        handleChange={() => {}}
        type="text"
      />
      <DatePickerComponent
        title="Expiry Date"
        name="expiry_date"
        icon={<CalendarIcon />}
        handleChange={() => {}}
        type="text"
        widthFull
      />
      <div className="flex flex-col gap-1">
        <span className="text-darkBlue text-sm font-medium">Front Image</span>
        <UploadBox />
        <span className="text-grayBg text-xs font-normal">Max size: 1 Mb</span>
      </div>
      <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
      <div className="flex gap-2 p-4 w-full md:w-72">
      <BorderedButton name="back" className="py-2 px-2" onClick={handelLastStep} />
      <RightTextButton
          name="continue"
          icon={<CorrectionMark />}
          onClick={handelNextStep}
          bgColor="bg-darkBlue"
        />
      </div>
    </div>
  );
};
export default DriversLisenceForm;
