import { Skeleton } from "@mui/material";

const TabsSkeleton = () => {
  return (
    <div className="flex justify-between gap-0.5 ">
      <Skeleton variant="rounded" width={125} height={44} />
      <Skeleton variant="rounded" width={125} height={44} />
      <Skeleton variant="rounded" width={125} height={44} />
    </div>
  );
};
export default TabsSkeleton;
