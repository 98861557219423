import { FunctionComponent } from "react";
import Icon from "../icon";
import { ThreeDots } from "react-loader-spinner";

interface ChildProps {
  name: string;
  onClick?: () => void;
  className?: string;
  icon?: JSX.Element;
  widthFull?: boolean;
  bgColor?: string;
  textColor?: string;
  loading: boolean;
}

const SubmitButton: FunctionComponent<ChildProps> = (props) => {
  return (
    <button
      type="submit"
      className={`${
        props.widthFull ? "w-full" : ""
      } h-12 rounded-md flex items-center justify-end gap-2 pt-6 pl-6 pr-2 pb-2 ${
        props.bgColor ? props.bgColor : "bg-primary"
      } hover:bg-secondary active:bg-darkBlue ${props.className}`}
      onClick={props.onClick}
    >
      {props.loading ? (
        <ThreeDots
          height="30"
          width="60"
          radius="9"
          color="#FFFFFF"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
        />
      ) : (
        <div className="flex justify-center items-center gap-2">
          {props.icon && <Icon icon={props.icon} size={16} />}
          <span
            className={`text-${
              props.textColor ? props.textColor : "white"
            } text-xs font-normal whitespace-nowrap`}
          >
            {props.name}
          </span>
        </div>
      )}
    </button>
  );
};

export default SubmitButton;
