import RightTextButton from "../../components/common/basics/rightTextButton";
import Icon from "../../components/common/icon";
import {
  FormChecking,
  MonitorLoading,
  Plus,
} from "../../components/common/svgIcons";

const EmptyResult = ({ reason, type }: { reason: string, type: string }) => {
  return (
    <div className="w-full py-16 lg:px-56 flex flex-col justify-center items-center gap-2 border border-borders/50 rounded-md">
      <Icon
        icon={reason === "no-account" ? <MonitorLoading /> : <FormChecking />}
        size={64}
      />
      <span className="text-text text-2xl font-medium whitespace-nowrap">
        {reason === "no-account" ? `No ${type} accounts` : "Verification Required"}
      </span>
      {reason === "not-autheticated" && (
        <span className="text-text text-sm font-normal">
          Please fill verification forms to be able to create live account.
        </span>
      )}
      {reason === "not-autheticated" && (
        <RightTextButton
          name="Verification Required"
          onClick={() => {}}
        />
      )}

      {reason === "no-account" && (
        <RightTextButton
          name="New Account"
          onClick={() => {}}
          icon={<Plus />}
        />
      )}
    </div>
  );
};

export default EmptyResult;
