import {
  CorrectionMark,
  Lock,
} from "../../components/common/svgIcons";
import PasswordValidationLayout from "../../components/layouts/passwordValidationLayout";
import { FunctionComponent, useState } from "react";
import SimpleButton from "../../components/common/basics/simpleButton";
import { SubmitHandler, useForm } from "react-hook-form";
import PasswordComponent from "../../components/common/basics/passwordComponent";
import PasswordCheckComponet from "../../components/common/basics/passwordCheckComponent";
import { passwordAnalyze } from "../../functions/passwordAnalyze";

export interface PasswordVerificationProps {
  handleChangeLevel: () => void;
}

interface IFormInputs {
  password: string;
}

const MakePassword: FunctionComponent<PasswordVerificationProps> = (props) => {
  const [passwordRules, setPasswordRules] = useState({
    digits: false,
    capital: false,
    number: false,
    lowercase: false,
    special: false,
  });
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInputs>({});

  const handleChangePassword: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const passwordValue = event.target.value;
    const analyze = passwordAnalyze(passwordRules, passwordValue);
    if (analyze) {
      clearErrors("password");
      // setValue("password", passwordValue);
    } else {
      setError("password", {
        type: "manual",
        message: "Password is not completed",
      });
    }
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    props.handleChangeLevel();
  };

  return (
    <PasswordValidationLayout titleName="Setup New Password" logo={<Lock />}>
      <form
        className="w-full flex flex-col gap-3 justify-center items-center py-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PasswordComponent
          handleChange={handleChangePassword}
          error={errors.password ? true : false}
          widthFull={true}
        />
        <PasswordCheckComponet passwordRules={passwordRules} />
        <SimpleButton
          name="Sign up"
          icon={<CorrectionMark />}
          className="my-3"
          onClick={() => !errors.password}
        />
      </form>
    </PasswordValidationLayout>
  );
};

export default MakePassword;
