import {
  AddressIcon,
  BankDetailIcon,
  FinancialIcon,
  ResidencyIcon,
  UserIcon,
  VehicleLicenseIcon,
} from "../components/common/svgIcons";

export interface verificationStepperInterface {
  name: string;
  icon: JSX.Element;
  required: boolean;
}

export const verificationFirstStepper: verificationStepperInterface[] = [
  {
    name: "Personal Details",
    icon: <UserIcon />,
    required: true,
  },
  {
    name: "Address",
    icon: <AddressIcon />,
    required: true,
  },
  {
    name: "Financial Questions",
    icon: <FinancialIcon />,
    required: true,
  },
];

export const verificationSecondStepper: verificationStepperInterface[] = [
  {
    name: "Identification Doc",
    icon: <AddressIcon />,
    required: true,
  },
  {
    name: "Driver’s License",
    icon: <VehicleLicenseIcon />,
    required: false,
  },
  {
    name: "Proof Of Residency",
    icon: <ResidencyIcon />,
    required: false,
  },
  {
    name: "Bank Details",
    icon: <BankDetailIcon />,
    required: true,
  },
];
