import { useState } from "react";
import EmailVerification from "./emialVerification";
import MakePassword from "./makePassword";
import ConfirmNewPassword from "./confirmNewPassword";

const PasswordVerification = () => { 
  const [level, setLevel] = useState(1)
  return (
    <div className="w-full p-6 border border-borders/50 rounded-md">
      {level === 1 && <EmailVerification handleChangeLevel={() => setLevel(2)}  />}
      {level === 2 && <MakePassword handleChangeLevel={() => setLevel(3)} />}
      {level === 3 && <ConfirmNewPassword />}
    </div>
  );
};

export default PasswordVerification;
