import { useState } from "react";
import Icon from "../../components/common/icon";
import { ArrowDown, ArrowUp } from "../../components/common/svgIcons";
import PagesInnerLayout from "../../components/layouts/pagesInnerLayout";

function IbDiagram() {
  const [openNode, setOpenNode] = useState<boolean>(true);

  return (
    <PagesInnerLayout title="IB Diagram">
      <div className="h-full max-w-fit flex flex-col justify-start items-center p-6 rounded-md border border-borders/50 ">
        <div
          className="flex justify-between items-center w-56 px-8 py-4 rounded-md border border-borders/50 bg-lightGray"
          onClick={() => setOpenNode(!openNode)}
        >
          <span>test test1 - 1658</span>
          <Icon
            icon={
              openNode ? (
                <ArrowUp color="#748692" />
              ) : (
                <ArrowDown color="#748692" />
              )
            }
            size={15}
          />
        </div>
        {openNode && (
          <div className="w-full relative">
            <div className="flex flex-col justify-center items-center absolute top-2 ">
              <div className="h-16 top-2 border-r border-gray-300"></div>
              <div className="flex justify-center items-center w-56 px-8 py-4 rounded-md border border-borders/50 bg-lightGray">
                <span>Ali Morakabi - 763</span>
              </div>
            </div>
          </div>
        )}
        <div className="w-full h-32"></div>
      </div>
    </PagesInnerLayout>
  );
}

export default IbDiagram;
