import { FunctionComponent } from "react";
import ModalHeader from "../../components/common/basics/modalHeader";

interface UserAgreementProps {
  handleClose: () => void;
}

const UserAgreement: FunctionComponent<UserAgreementProps> = (props) => {
  return (
    <div className="h-96 lg:h-[600px] overflow-y-auto flex flex-col justify-start items-start gap-6">
      <ModalHeader
        title="User
        Agreement"
        // name={props.code}
        buttonName="Back to My Profile"
        onClickButton={props.handleClose}
      />
      <p className="text-text text-sm font-normal">
        Our mission is to connect the world’s professionals to allow them to be
        more productive and successful. Our services are designed to promote
        economic opportunity for our members by enabling you and millions of
        other professionals to meet, exchange ideas, learn, and find
        opportunities or employees, work, and make decisions in a network of
        trusted relationships.{" "}
      </p>
      <span className="text-text text-sm font-normal">able of Contents:</span>
      <span className="text-text text-sm font-normal">1. Introduction</span>
      <span className="text-text text-sm font-normal">2. Obligations</span>
      <span className="text-text text-sm font-normal">
        3. Rights and Limits
      </span>
      <span className="text-text text-sm font-normal">
        4. Disclaimer and Limit of Liability
      </span>
      <span className="text-text text-sm font-normal">5. Termination</span>
      <span className="text-text text-sm font-normal">
        6. Governing Law and Dispute Resolution
      </span>
      <span className="text-text text-sm font-normal">7. General Terms</span>
      <span className="text-text text-sm font-normal">
        8. LinkedIn “Dos and Don’ts”
      </span>
      <span className="text-text text-sm font-normal">
        9. Complaints Regarding Content
      </span>
      <span className="text-text text-sm font-normal">
        10. How To Contact Us
      </span>
      <span className="text-text text-sm font-normal">Introduction</span>
      <span className="text-text text-sm font-normal">1.1 Contract</span>
      <p className="text-text text-sm font-normal">
        When you use our Services you agree to all of these terms. Your use of
        our Services is also subject to our Cookie Policy and our Privacy
        Policy, which covers how we collect, use, share, and store your personal
        information. You agree that by clicking “Join Now”, “Join LinkedIn”,
        “Sign Up” or similar, registering, accessing or using our services
        (described below), you are agreeing to enter into a legally binding
        contract with LinkedIn (even if you are using our Services on behalf of
        a company). If you do not agree to this contract (“Contract” or “User
        Agreement”), do not click “Join Now” (or similar) and do not access or
        otherwise use any of our Services. If you wish to terminate this
        contract, at any time you can do so by closing your account and no
        longer accessing or using our Services. Services This Contract applies
        to LinkedIn.com, LinkedIn-branded apps, LinkedIn Learning and other
        LinkedIn-related sites, apps, communications and other services that
        state that they are offered under this Contract (“Services”), including
        the offsite collection of data for those Services, such as our ads and
        the “Apply with LinkedIn” and “Share with LinkedIn” plugins. Registered
        users of our Services are “Members” and unregistered users are
        “Visitors”.
      </p>
    </div>
  );
};
export default UserAgreement;
