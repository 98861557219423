import { useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { languageOptions } from "../../../constants/languageOptions";
import Icon from "../icon";

const LanguageSelector = () => {
  const [lang, setLang] = useState(languageOptions[0]);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedLang = languageOptions.find(
      (lang) => lang.value === event.target.value
    );
    selectedLang && setLang(selectedLang);
  };
  return (
    <div>
      <FormControl sx={{ minWidth: 67 }}>
        <Select
          value={lang.value}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className="h-11"
          renderValue={(value) => {
            const selectedOption = languageOptions.find(
              (option) => option.value === value
            );
            return selectedOption ? (
              <Icon icon={selectedOption.icon} size={24} />
            ) : (
              ""
            );
          }}
        >
          {languageOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              className="flex justify-center items-center gap-2"
            >
              <Icon icon={option.icon} size={24} />
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default LanguageSelector;
