import { useState } from "react";
import ModalComponent from "../../components/common/basics/modalcomponent";
import { History, Setting } from "../../components/common/svgIcons";
import PagesInnerLayout from "../../components/layouts/pagesInnerLayout";
import TabsComponent from "../../components/common/basics/tabsComponent";
import TransactionHistory from "./transactionHistory";
import WalletBaseComponent from "./walletBaseComponent";
import BankAccountsComponent from "./bankAccounts";
import AddNewBank from "./addNewBank";

const Wallet = () => {
  const [openModal, setOpenModal] = useState<string | null>(null);
  const [selected, setSelected] = useState<string>("Deposit");

  const handleClose = () => {
    setOpenModal(null);
  };

  return (
    <PagesInnerLayout
      title={selected}
      buttonName="Transaction History"
      buttonIcon={<History />}
      handleClickButton={() => setOpenModal("transaction_history")}
      buttonNameSecond="Manage Bank Accounts"
      buttonIconSecond={<Setting />}
      handleClickButtonSecond={() => setOpenModal("bank_account")}
    >
      <div className="w-full lg:max-w-fit flex flex-col justify-start items-start gap-6 border border-borders/50 rounded-md p-5 md:p-6">
        <TabsComponent
          tabList={["Deposit", "Withdraw"]}
          onClickTab={(name) => setSelected(name)}
          selected={selected}
        />
        <hr className="w-full bg-borders/50 flex-grow h-0.5" />
        <WalletBaseComponent selected={selected} />
      </div>
      <ModalComponent
        open={openModal === "transaction_history"}
        onClose={handleClose}
      >
        <TransactionHistory handleClose={handleClose} />
      </ModalComponent>
      <ModalComponent open={openModal === "bank_account"} onClose={handleClose}>
        <BankAccountsComponent
          handleOpenNewBank={() => setOpenModal("add_new_bank")}
          handleClose={handleClose}
        />
      </ModalComponent>
      <ModalComponent open={openModal === "add_new_bank"} onClose={handleClose}>
        <AddNewBank handleClose={handleClose} />
      </ModalComponent>
    </PagesInnerLayout>
  );
};

export default Wallet;
