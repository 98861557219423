import { FunctionComponent } from "react";
import Icon from "../icon";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface DatePickerPropsInterface {
  name: string;
  title?: string;
  icon: JSX.Element;
  type: string;
  handleChange: (value: string | null) => void;
  className?: string;
  widthFull?: boolean;
  width?: string;
}

const DatePickerComponent: FunctionComponent<DatePickerPropsInterface> = (
  props
) => {
  return (
    <div className={`w-full flex flex-col gap-1  ${props.className}`}>
      <span className="text-text text-sm font-medium ml-9 whitespace-nowrap">{props.title}</span>
      <div className="w-full flex items-center gap-2">
        <Icon icon={props.icon} size={24} />
        <div className={`${props.widthFull ? "w-full" : props.width ? props.width : "lg:w-[134px]"}`}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              defaultValue={dayjs("2022-04-17")}
              sx={{ "& .MuiInputBase-root": { height: "44px"}, width: "100%" }}
              onChange={(date) => props.handleChange(date ? dayjs(date).format('YYYY-MM-DD') : null)}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};
export default DatePickerComponent;
