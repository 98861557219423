import { FilterIcon } from "../../components/common/svgIcons";
import IbLayout from "../../components/layouts/ibLayout";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import TableComponent from "../account/tableComponent";
import { ibLeadsTableKeys } from "../../constants/ibTablesData";
import { useState } from "react";
import IbPagesInnerLayout from "../../components/layouts/ibPagesInnerLayout ";

const IbLeads = () => {
  const [openFilterNav, setOpenFilterNav] = useState<boolean>(true);

  return (
    <IbPagesInnerLayout
      handleSwitchFilters={() => setOpenFilterNav(!openFilterNav)}
    >
      <div className="w-full lg:max-w-fit flex flex-col justify-start items-start gap-6">
        <IbLayout title="Leads" openFilterNav={openFilterNav}>
          <div className="w-full md:w-auto">
            <DropDownComponent
              name="columns"
              icon={<FilterIcon className="text-text" />}
              selectList={testList}
              handleSelect={() => {}}
              placeHolder="All Columns Selected"
              width="w-full md:w-[294px]"
            />
          </div>
        </IbLayout>
        <div className="w-full overflow-x-auto sc-sp2 flex flex-col justify-start items-start">
          <TableComponent keys={ibLeadsTableKeys} />
        </div>
      </div>
    </IbPagesInnerLayout>
  );
};

export default IbLeads;
