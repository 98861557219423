import { FunctionComponent } from "react";
import Icon from "../icon";
import { CheckMark } from "../svgIcons";

interface TabsCompnentProps {
  tabList: string[];
  onClickTab: (id: string) => void;
  className?: string;
  selected: string;
}

const TabsComponent: FunctionComponent<TabsCompnentProps> = (props) => {
  return (
    <div className="w-full md:w-auto lg:h-12 flex rounded-md border border-primary divide-x divide-primary  ">
      {props.tabList.map((tab, index) => (
        <div
          key={index}
          className={`w-full h-full md:w-auto flex items-center gap-2 pt-6 pl-6 pr-2 pb-2 cursor-pointer ${
            props.selected === tab ? "bg-primary" : ""
          } ${
            props.selected === tab ? "text-white" : "text-primary"
          } hover:text-white hover:bg-secondary active:bg-darkBlue ${props.className}`}
          onClick={() => props.onClickTab(tab)}
        >
          {props.selected === tab && (
            <Icon icon={<CheckMark />} size={11} className={`text-white`} />
          )}
          <span className={`  text-xs font-normal md:whitespace-nowrap `}>{tab}</span>
        </div>
      ))}
    </div>
  );
};
export default TabsComponent;
