import { Dollar, Hashtag } from "../../components/common/svgIcons";
import InputComponent from "../../components/common/basics/inputComponent";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";

const CryptoStartForm = () => {
  return (
    <div className="flex flex-col justify-center md:grid md:grid-cols-2 gap-6">
      <InputComponent
        name="wallet_id"
        title="Your Wallet ID:"
        icon={<Hashtag className="text-darkBlue" />}
        type="text"
        // handleChange={(value) => setValue("wallet_id", value)}
        // register={register}
        required
        // error={errors.wallet_id ? true : false}
        widthFull={true}
      />
      <DropDownComponent
        name="pey_method"
        title="Your pay with:"
        icon={<Dollar className="text-darkBlue" />}
        selectList={testList}
        handleSelect={(value) => {}}
        required
        // register={register}
        // error={errors.pey_method ? true : false}
        widthFull
      />
     <InputComponent
        name="amount"
        title="Set Amount"
        icon={<Dollar className="text-darkBlue" />}
        type="text"
        // handleChange={(value) => setValue("amount", value)}
        // register={register}
        required
        // error={errors.amount ? true : false}
        widthFull={true}
      />
    </div>
  );
};
export default CryptoStartForm;
