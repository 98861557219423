import Icon from "../../components/common/icon";
import { Langs } from "../../components/common/svgIcons";
import { FunctionComponent } from "react";
import "react-phone-input-2/lib/style.css";
import LanguageSelector from "../common/basics/languageSelector";

export interface AuthenticationLayoutProps {
  children?: React.ReactNode;
}

const AuthenticationPageLayout: FunctionComponent<AuthenticationLayoutProps> = (
  props
) => {
  return (
    <div className="w-full lg:max-h-screen lg:overflow-hidden flex flex-col-reverse lg:justify-center lg:grid lg:grid-cols-2 lg:justify-items-center ">
      <div className="w-full h-screen flex flex-col items-center lg:overflow-y-auto px-4 lg:px-12 sc-sp2">
        <div className="w-full md:max-w-fit flex flex-col items-center">
          <div className="w-full flex justify-between py-6 ">
            <div className="flex flex-col">
              <span className="font-light text-2xl">Welcome to </span>
              <span className="font-semibold text-2xl">FXParamount!</span>
            </div>
            <div className="flex flex-col justify-center items-center relative">
              <Icon
                icon={<Langs />}
                size={24}
                className="absolute -left-8 top-9"
              />
              <span>language</span>
              <LanguageSelector />
            </div>
          </div>
          {props.children}
        </div>
      </div>
      <div className="w-full relative">
        <img
          className="w-full h-52 md:h-96 lg:h-screen object-cover object-top "
          src="/images/BG.png"
          alt="paramount loginSingup"
        />
        <div className="hidden lg:flex flex-col absolute bottom-12 transform left-1/2 -translate-x-1/2 container mx-auto px-10">
          <div className="flex flex-col">
            <p className="text-sm font-normal text-white">
              <span className="text-white/50">Risk Disclaimer:</span> Trading
              Forex and Leveraged Financial Instruments is highly risk and can
              result in the loss of all invested funds. You should not invest
              more than you can afford to lose, and you should understand the
              risks involved. Trading leveraged products is not suitable for all
              investors. Please consider your level of experience, investment
              objectives, and, if necessary, seek independent financial advice
              before trading. It is the Client's responsibility to determine
              whether he or she is legally permitted to use the services of
              Paramount Markets Limited in their country of residence.
            </p>
            <p className="text-sm font-normal text-white mt-4">
              <span className="text-white/50">Regional restrictions:</span>
               Paramount Markets Limited does not provide services to residents
              of the USA, Canada, Australia, the Democratic Republic of Korea,
              European Union, United Kingdom, Iran, Syria, Sudan and Cuba.
            </p>
            <p className="text-sm font-normal text-white/50 mt-4">
              copyright © 2024 Paramount. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationPageLayout;
