import { FC } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { formatString } from "../../../functions/formString";
export interface LineChartProps {
  data: { name: string; best_trade: number; worst_trade: number }[];
  dataKeys: { name: string; color: string }[];
}

const LineChartComponent: FC<LineChartProps> = ({ data, dataKeys }) => {
  return (
    <div className="w-full lg:w-auto relative flex justify-center items-center mt-6 ">
      <ResponsiveContainer width="100%" height={330} aspect={1}>
        <LineChart data={data}>
          <XAxis
            dataKey="name"
            tick={{ fontSize: 14, fontWeight: 600 }}
            tickLine={false}
            axisLine={false}
            interval={0}
            stroke="gray"
            // tickFormatter={(value, index) => {
            //   if (index % 2 === 0) {
            //     return value;
            //   }
            //   return null;
            // }}
          />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend
            content={
              <div className="w-full flex justify-center items-center gap-10 mt-6">
                {dataKeys.map((key, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center gap-2"
                  >
                    <div
                      className="h-6 w-1 rounded-md"
                      style={{ backgroundColor: key.color }}
                    />
                    <span className="text-base font-normal text-text">
                      {formatString(key.name)}
                    </span>
                  </div>
                ))}
              </div>
            }
            // formatter={(value, index) => <span>{formatString(value)}</span>}
          />
          {dataKeys.map((dataItem, index) => (
            <Line
              key={index}
              type="monotone"
              dataKey={dataItem.name}
              stroke={dataItem.color}
              strokeWidth={3}
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComponent;
