import { Dollar, Hashtag } from "../../components/common/svgIcons";
import InputComponent from "../../components/common/basics/inputComponent";

const CryptoConfirmForm = () => {
  return (
    <div className="flex flex-col justify-center md:grid md:grid-cols-2 gap-6">
      <InputComponent
        name="wallet_id"
        title="Your Wallet ID:"
        icon={<Hashtag className="text-borders" />}
        type="text"
        // handleChange={(value) => setValue("wallet_id", value)}
        // register={register}
        disabled
        // error={errors.wallet_id ? true : false}
        widthFull={true}
      />
      <InputComponent
        name="from_currency"
        title="From Currency:"
        icon={<Dollar className="text-darkBlue" />}
        type="text"
        // handleChange={(value) => setValue("from_currency", value)}
        // register={register}
        required
        // error={errors.from_currency ? true : false}
        widthFull={true}
      />
      <InputComponent
        name="to_currency"
        title="To Currency:"
        icon={<Dollar className="text-darkBlue" />}
        type="text"
        // handleChange={(value) => setValue("to_currency", value)}
        // register={register}
        required
        // error={errors.to_currency ? true : false}
        widthFull={true}
      />
      <InputComponent
        name="your_amount"
        title="Your Amount:"
        icon={<Dollar className="text-darkBlue" />}
        type="text"
        // handleChange={(value) => setValue("your_amount", value)}
        // register={register}
        required
        // error={errors.your_amount ? true : false}
        widthFull={true}
      />
      <InputComponent
        name="estimated_amount"
        title="Estimated Amount:"
        icon={<Dollar className="text-darkBlue" />}
        type="text"
        // handleChange={(value) => setValue("estimated_amount", value)}
        // register={register}
        required
        // error={errors.estimated_amount ? true : false}
        widthFull={true}
      />
    </div>
  );
};
export default CryptoConfirmForm;
