import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";

const TableSkeleton = () => {
  return (
    <TableBody>
      {[...Array(10)].map((row, index) => (
        <TableRow key={index} className={index % 2 === 0 ? 'bg-lightGray' : 'odd-row'}>
        <TableCell>
            <Skeleton animation="wave" variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton animation="wave" variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton animation="wave" variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton animation="wave" variant="text" width={100} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
export default TableSkeleton;
