import Icon from "../components/common/icon";
import { DeleteIcon, EditIcon } from "../components/common/svgIcons";

export const bankAccountsKeys = [
  "Bank Name",
  "Beneficiary Name",
  "Beneficiary Account Number",
  "Actions",
];

export const colorObject: { [key: string]: string } = {
  Denied: "bg-error",
  Approved: "bg-primary",
  Pending: "bg-borders",
};

const handleEdit = () => {};

const handleDelete = () => {};

const ActionsElementDivision = (
  <div className="flex gap-1 justify-center items-cener">
    <div onClick={handleEdit} className="p-2.5 cursor-pointer rounded-md bg-primary">
      <Icon icon={<EditIcon />} size={20} />
    </div>
    <div onClick={handleDelete} className="p-2.5 cursor-pointer rounded-md bg-error">
      <Icon icon={<DeleteIcon />} size={20} />
    </div>
  </div>
);

export const bankAccountTestData = [
  {
    bank_name: "Mellat",
    beneficiary_name: "Ahmad Rezaie",
    beneficiary_account_number: 280536473,
    actions: ActionsElementDivision,
  },
  {
    bank_name: "Mellat",
    beneficiary_name: "Ahmad Rezaie",
    beneficiary_account_number: 280536473,
    actions: ActionsElementDivision,
  },
  {
    bank_name: "Mellat",
    beneficiary_name: "Ahmad Rezaie",
    beneficiary_account_number: 280536473,
    actions: ActionsElementDivision,
  },
  {
    bank_name: "Mellat",
    beneficiary_name: "Ahmad Rezaie",
    beneficiary_account_number: 280536473,
    actions: ActionsElementDivision,
  },
  {
    bank_name: "Mellat",
    beneficiary_name: "Ahmad Rezaie",
    beneficiary_account_number: 280536473,
    actions: ActionsElementDivision,
  },
  {
    bank_name: "Mellat",
    beneficiary_name: "Ahmad Rezaie",
    beneficiary_account_number: 280536473,
    actions: ActionsElementDivision,
  },
  {
    bank_name: "Mellat",
    beneficiary_name: "Ahmad Rezaie",
    beneficiary_account_number: 280536473,
    actions: ActionsElementDivision,
  },
];
