import { Checkbox } from "@mui/material";
import Icon from "../../components/common/icon";
import {
  Email,
  FlagNew,
  LargeCkeckBox,
  LargeSelectedCheckBox,
  Profile,
  ProfileCircle,
} from "../../components/common/svgIcons";
import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import "react-phone-input-2/lib/style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import SimpleButton from "../../components/common/basics/simpleButton";
import InputComponent from "../../components/common/basics/inputComponent";
import PhoneComponent from "../../components/common/basics/phoneComponent";
import PasswordComponent from "../../components/common/basics/passwordComponent";
import PasswordCheckComponet from "../../components/common/basics/passwordCheckComponent";
import { passwordAnalyze } from "../../functions/passwordAnalyze";

interface IFormInputs {
  name: string;
  last_name: string;
  country: string;
  cellphone_number: number;
  email: string;
  password: string;
  agree: boolean;
  send_email: boolean;
}

const SignupForm = () => {
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordRules, setPasswordRules] = useState({
    digits: false,
    capital: false,
    number: false,
    lowercase: false,
    special: false,
  });
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      agree: false,
      send_email: false,
      country: "US",
    },
  });

  const onSelect = (code: string) => setValue("country", code);

  const handleChangePassword: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const passwordValue = event.target.value;
    const analyze = passwordAnalyze(passwordRules, passwordValue);
    if (analyze) {
      clearErrors("password");
      setValue("password", passwordValue);
    } else {
      setError("password", {
        type: "manual",
        message: "Password is not completed",
      });
    }
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    if (phone) {
      setLoading(true);
      setTimeout(() => setLoading(false), 2000);
    } else {
      setError("cellphone_number", {
        type: "manual",
        message: "Phone number is required",
      });
    }
  };

  return (
    <form
      className="w-full flex flex-col gap-3 justify-center items-center py-3"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full flex flex-col gap-2 ">
        <span className="text-text text-sm font-medium ml-9">
          Select Country
        </span>
        <div className="w-full flex items-center gap-2">
          <Icon icon={<FlagNew />} size={24} />
          <ReactFlagsSelect
            selected={watch("country")}
            onSelect={onSelect}
            {...register("name", { required: true })}
            className={`w-full ${
              errors.country ? "border border-red-500" : ""
            }`}
            // className="w-full border-2 border-red-700 rounded-md"
            // style={{ height: '48px' }}
          />
        </div>
      </div>
      <div className="w-full grid grid-cols-2 justify-between gap-2.5">
        <InputComponent
          name="name"
          title="First Name"
          icon={<Profile className="text-darkBlue" />}
          type="text"
          handleChange={(value) => setValue("name", value)}
          register={register}
          required
          error={errors.name ? true : false}
          widthFull={true}
        />
        <InputComponent
          name="last_name"
          title="Last Name"
          icon={<Profile className="text-darkBlue" />}
          type="text"
          handleChange={(value) => setValue("last_name", value)}
          register={register}
          required
          error={errors.last_name ? true : false}
          widthFull={true}
        />
      </div>
      <PhoneComponent
        handleChange={(val) => {
          setPhone(val);
          val && clearErrors("cellphone_number");
        }}
        error={errors.cellphone_number ? true : false}
        widthFull
      />
      <InputComponent
        name="email"
        title="Email Address"
        icon={<Email className="text-darkBlue" />}
        type="text"
        handleChange={(value) => setValue("email", value)}
        register={register}
        required
        error={errors.email ? true : false}
        widthFull={true}
      />
      <PasswordComponent
        handleChange={handleChangePassword}
        error={errors.password ? true : false}
        widthFull={true}
      />
      <PasswordCheckComponet passwordRules={passwordRules} />
      <div className="w-full flex items-center gap-2 mt-2">
        <Checkbox
          icon={<LargeCkeckBox />}
          checkedIcon={<LargeSelectedCheckBox />}
          {...register("agree", { required: true })}
          sx={{
            padding: 0,
          }}
        />
        <span className="text-sm font-normal">
          I, Agree with <span className="text-primary">Terms</span> &{" "}
          <span className="text-primary">Conditions</span>
        </span>
      </div>
      <div className="w-full flex items-center gap-2">
        <Checkbox
          icon={<LargeCkeckBox />}
          checkedIcon={<LargeSelectedCheckBox />}
          {...register("send_email", { required: true })}
          sx={{
            padding: 0,
          }}
        />
        <span className="text-sm font-normal">
          I wish to receive company news and products updates.
        </span>
      </div>
      <SimpleButton
        name="Sign up"
        icon={<ProfileCircle />}
        loading={loading}
        className="my-3"
      />
      {errors.agree && (
        <span className="text-xs font-light text-red-700">
          please check the agreement conditions.
        </span>
      )}
    </form>
  );
};

export default SignupForm;
