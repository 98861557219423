import { useState } from "react";
import PagesInnerLayout from "../../components/layouts/pagesInnerLayout";
import {
  verificationFirstStepper,
  verificationSecondStepper,
} from "../../constants/verificationSteppers";
import StepperComponent from "../../components/common/basics/stepperComponent";
import PersonalDetailForm from "./personalDetailsForm";
import AddressForm from "./addressForm";
import FinacialQuestionsForm from "./finacialQuestionsForm";
import IdentificationDoc from "./identificationDocForm";
import DriversLisenceForm from "./driversLisenceForm";
import ProofOfResidencyForm from "./proofOfResidencyForm";
import BankDetailsForm from "./bankDetailForm";

const Verification = () => {
  const [level, setLevel] = useState<number>(1);
  const [activeStep, setActiveStep] = useState<number>(0);

  return (
    <PagesInnerLayout title={verificationFirstStepper[0].name}>
      <div className="w-full flex flex-col justify-start items-start gap-6 border border-borders/50 rounded-md p-5 md:p-6">
        {level === 1 && (
          <>
            <StepperComponent
              steps={verificationFirstStepper}
              activeStep={activeStep}
            />
            {activeStep === 0 && (
              <PersonalDetailForm
                handelNextStep={() => setActiveStep(activeStep + 1)}
              />
            )}
            {activeStep === 1 && (
              <AddressForm
                handelNextStep={() => setActiveStep(activeStep + 1)}
                handelLastStep={() => setActiveStep(activeStep - 1)}
              />
            )}
            {activeStep === 2 && (
              <FinacialQuestionsForm
                handelNextStep={() => {
                  setLevel(2);
                  setActiveStep(0);
                }}
                handelLastStep={() => setActiveStep(activeStep - 1)}
              />
            )}
          </>
        )}
        {level === 2 && (
          <>
            <StepperComponent
              steps={verificationSecondStepper}
              activeStep={activeStep}
            />
            {activeStep === 0 && (
              <IdentificationDoc
                handelNextStep={() => setActiveStep(activeStep + 1)}
                handelLastStep={() => setActiveStep(activeStep - 1)}
              />
            )}
            {activeStep === 1 && (
              <DriversLisenceForm
                handelNextStep={() => setActiveStep(activeStep + 1)}
                handelLastStep={() => setActiveStep(activeStep - 1)}
              />
            )}
            {activeStep === 2 && (
              <ProofOfResidencyForm
                handelNextStep={() => setActiveStep(activeStep + 1)}
                handelLastStep={() => setActiveStep(activeStep - 1)}
              />
            )}
            {activeStep === 3 && (
              <BankDetailsForm
                handelNextStep={() => {setActiveStep(0); setLevel(1)}}
                handelLastStep={() => setActiveStep(activeStep - 1)}
              />
            )}
          </>
        )}
      </div>
    </PagesInnerLayout>
  );
};

export default Verification;
