import {
  CorrectionMark,
  Hashtag,
  UserIcon,
} from "../../components/common/svgIcons";
import { FunctionComponent, useState } from "react";
import InputComponent from "../../components/common/basics/inputComponent";
import RightTextButton from "../../components/common/basics/rightTextButton";
import BorderedButton from "../../components/common/basics/borderedButton";

interface BankDetailsPropsInterface{
    handelNextStep: () => void;
    handelLastStep: () => void;
}

const BankDetailsForm: FunctionComponent<BankDetailsPropsInterface> = ({handelNextStep, handelLastStep}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-6">
      <InputComponent
        title="Card Holder Name"
        name="card_holder_name"
        icon={<UserIcon />}
        handleChange={() => {}}
        type="text"
      />
      <InputComponent
        title="Card Holder ID"
        name="card_holder_id"
        icon={<Hashtag />}
        handleChange={() => {}}
        type="text"
      />
      <InputComponent
        title="Bank Name"
        name="bank_name"
        icon={<Hashtag />}
        handleChange={() => {}}
        type="text"
      />
      <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
      <div className="flex gap-2 p-4 w-full md:w-72">
      <BorderedButton name="back" className="py-2 px-2" onClick={handelLastStep} />
        <RightTextButton
          name="save"
          icon={<CorrectionMark />}
          onClick={handelNextStep}
        />
      </div>
    </div>
  );
};
export default BankDetailsForm;
