import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Icon from "../icon";
import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";

interface DropDownComponentInterface {
  name: string;
  title?: string;
  icon?: JSX.Element;
  selectList: { name: string; value: number }[];
  handleSelect?: (value: string) => void;
  placeHolder?: string;
  className?: string;
  widthFull?: boolean;
  width?: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  error?: boolean;
  value?: string;
}

const DropDownComponent: FunctionComponent<DropDownComponentInterface> = ({
  name,
  title,
  icon,
  selectList,
  handleSelect,
  placeHolder,
  className,
  widthFull,
  width,
  register,
  required,
  error,
  value
}) => {
  
  const handleInputChange = (e: SelectChangeEvent) => {
    handleSelect && handleSelect(e.target.value as string);
  };

  return (
    <div className={`w-full flex flex-col gap-1 ${className}`}>
      <span className="text-text text-sm font-medium ml-9 whitespace-nowrap">
        {title}
      </span>
      <div className="flex items-center gap-2">
        {icon && <Icon icon={icon} size={24} />}
        <FormControl
          className={`m-1 ${
            widthFull ? "w-full" : width ? width : "lg:w-[294px]"
          }`}
        >
          <Select
            value={value || ""}
            displayEmpty  // Add displayEmpty prop here
            placeholder={placeHolder || "Select an option"}
            inputProps={{
              "aria-label": "Without label",
              style: { height: 44 },
            }}
            className="h-11"
            // onChange={handleInputChange}
            {...(register
              ? register(name, { required })
              : {})}
            error={error}
            onChange={(e) => handleSelect && handleSelect(e.target.value as string)}
          >
            <MenuItem value="" disabled>
              <em>{placeHolder || "Select an option"}</em>
            </MenuItem>
            {selectList.map((itemList, index) => (
              <MenuItem key={index} value={itemList.value}>
                {itemList.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
export default DropDownComponent;
