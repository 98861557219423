import { Box, Skeleton } from "@mui/material";

const CardSkeleton = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-2">
      <Skeleton variant="rectangular" width={332} height={172} />
      <div className="w-full flex justify-between">
        <Skeleton variant="rounded" width={200} height={90} />
        <Skeleton variant="rounded" width={110} height={90} />
      </div>
    </div>
  );
};
export default CardSkeleton;
