import { FunctionComponent } from "react";
import Icon from "../icon";
import { ThreeDots } from "react-loader-spinner";

interface ChildProps {
  name: string;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  icon?: JSX.Element;
  widthFull?: boolean;
}

const SimpleButton: FunctionComponent<ChildProps> = (props) => {
  return (
    <button
      className={`w-full rounded-md flex justify-center items-center gap-2 py-2 bg-primary hover:bg-secondary active:bg-darkBlue ${props.className}`}
      onClick={props.onClick}
    >
      {props.loading ? (
        <ThreeDots
          height="30"
          width="60"
          radius="9"
          color="#FFFFFF"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          visible={true}
        />
      ) : (
        <div className="flex justify-center items-center gap-2">
          {props.icon && <Icon icon={props.icon} size={24} />}
          {props?.name && (
            <span className="text-white text-sm font-normal whitespace-nowrap">{props.name}</span>
          )}
        </div>
      )}
    </button>
  );
};

export default SimpleButton;
