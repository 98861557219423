import { FunctionComponent, useState, useRef } from "react";
import {
  Bell,
  Logout,
  ParamountIcon,
  ParamountLogo,
  PhoneRing,
  Profile,
} from "../svgIcons";
import LanguageSelector from "./languageSelector";
import BorderedButton from "./borderedButton";
import SimpleButton from "./simpleButton";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useHistory } from "react-router-dom";

export interface HeaderProps {
  title: string;
}
const Header: FunctionComponent<HeaderProps> = (props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const closeModal = () => {
    setOpenModal(false);
  };

  useOutsideClick(modalRef, closeModal);

  return (
    <div className="w-full h-16 top-0 flex items-center justify-between p-2 border-b border-b-borders/50 relative">
      <div className="flex items-center gap-12">
        <ParamountLogo className="hidden lg:flex" />
        <ParamountIcon className="flex lg:hidden" />
        {/* <span className="text-2xl font-medium">{props.title}</span> */}
      </div>
      <div className="flex justify-end items-center gap-2 relative">
        <div className="hidden lg:flex">
          <LanguageSelector />
        </div>
        <BorderedButton icon={<Bell />} className="py-2 px-2" />
        <SimpleButton
          name="AG"
          icon={<Profile className="text-white" />}
          className="w-22 p-2"
          onClick={() => setOpenModal(!openModal)}
        />
        {openModal && (
          <div
            ref={modalRef}
            className="w-52 bg-white absolute top-14 z-50 rounded-md border border-borders/50"
          >
            <div className="relative px-8 py-6 shadow-md ">
              <span className="absolute -top-3 right-6 rounded-md w-6 h-6 transform rotate-45 border-t border-t-borders/50 border-l border-l-borders/50 bg-white"></span>
              <div className="flex flex-col divide-y divide-borders/50">
                <div className="flex flex-col justify-center items-start py-2">
                  <span className="text-sm font-normal text-text">
                    Amir Ghavanloo
                  </span>
                  <span className="text-xs font-normal text-primary">
                    ghavanlooa@gmail.com
                  </span>
                </div>
                <div className="flex justify-start items-center gap-2 py-3 cursor-pointer" onClick={() => history.push('/profile')}>
                  <Profile className="text-darkBlue" />
                  <span className="text-sm font-normal text-text">Profile</span>
                </div>
                <div className="flex justify-start items-center gap-2 py-3 cursor-pointer">
                  <PhoneRing className="text-darkBlue" />
                  <span className="text-sm font-normal text-text">
                    Contact Us
                  </span>
                </div>
                <div className="flex justify-start items-center gap-2 py-3 cursor-pointer">
                  <Logout className="text-darkBlue" />
                  <span className="text-sm font-normal text-text">Log Out</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
