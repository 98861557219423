import { useEffect, useState } from "react";
import Icon from "../../components/common/icon";
import { IncreaseIcon } from "../../components/common/svgIcons";

const ROIComponent = () => {
  const [value, setValue] = useState<number>();

  useEffect(() => {
    setValue(-305.16);
  }, []);

  return (
    <div className="flex flex-col justify-start items-start gap-4 bg-white">
      <span className="text-2xl font-medium">ROI</span>
      <div className="flex flex-col justify-start items-center gap-6 p-6 rounded-md border border-borders/50 relative">
        <div className="w-full">
        <Icon icon={<IncreaseIcon />} size={60} />
        </div>
        <span className="text-4xl font-semibold">{value}</span>
        <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
        <span className="text-sm font-light">Since Inception</span>
      </div>
    </div>
  );
};
export default ROIComponent;
