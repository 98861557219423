import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RightTextButton from "../../components/common/basics/rightTextButton";
import { Back, BrokerageLogo } from "../../components/common/svgIcons";
import AccountsCard, { AccountCardParops } from "../accounts/accountCard";
import TabsComponent from "../../components/common/basics/tabsComponent";
import TableComponent from "./tableComponent";
import {
  accountTableKeys,
  accountTableTestData,
} from "../../constants/accountTableTest";
import ModalComponent from "../../components/common/basics/modalcomponent";
import TransferComponent from "./transferCompnent";
import DepositComponent from "./depositComponent";
import WithdrawComponent from "./withdrawComponent";
import AccountCardSkeleton from "../../components/common/skeletons/accountCardSkeleton";
import TableSkeleton from "../../components/common/skeletons/tableSkeleton";
import CardSkeleton from "../../components/common/skeletons/cardSkeleton";
import TabsSkeleton from "../../components/common/skeletons/tabsSkeleton";

export interface TableDataInterface {
  open_time: string;
  type: string;
  lots: number;
  symbol: string;
  price: number;
  s_l: number;
  t_p: number;
  gain: number;
  current_price: number;
}

const Account = () => {
  const [data, setData] = useState<AccountCardParops | undefined>();
  const [tableData, setTableData] = useState<
    TableDataInterface[] | undefined
  >();
  const [selected, setSelected] = useState<string>("Open Positions");
  const [openModal, setOpenModal] = useState<string | null>(null);

  const history = useHistory();

  useEffect(() => {
    getAccountInfo();
  }, []);

  useEffect(() => {
    selected === "Open Positions" && getOpenPositionsInfo();
  }, [selected]);

  const getAccountInfo = () => {
    setTimeout(
      () =>
        setData({
          name: "Demo Zero",
          code: "USD - 1001",
          price: 98000,
          time: "1:50",
          status: true,
          logo: <BrokerageLogo />,
          id: 1,
          margin: 0,
          equity: 0,
          credit: 0,
          free_margin: 0,
        }),
      1000
    );
  };

  const getOpenPositionsInfo = () => {
    setTimeout(() => setTableData(accountTableTestData), 1000);
  };

  const handlePushBack = () => {
    history.push("/live-accounts");
  };

  const handleClose = () => {
    setOpenModal(null);
  };

  return (
    <div className="w-full flex flex-col items-start gap-2">
      <RightTextButton
        name="Back to Accounts"
        icon={<Back />}
        onClick={handlePushBack}
        bgColor="bg-darkBlue"
      />
      <div className="w-full flex flex-col lg:flex-row justify-start items-start gap-4">
        {data ? (
          <div className="w-full md:w-auto flex flex-col items-start gap-2">
            <AccountsCard
              name={data.name}
              code={data.code}
              price={data.price}
              time={data.time}
              status={data.status}
              logo={data.logo}
              isOpen={true}
              margin={data.margin}
              credit={data.credit}
              equity={data.equity}
              free_margin={data.free_margin}
            />
            <div className="w-full flex justify-between gap-2 px-1">
              <RightTextButton
                onClick={() => setOpenModal("Deposit")}
                widthFull={true}
                name="Deposit"
                icon={<Back />}
              />
              <RightTextButton
                onClick={() => setOpenModal("Withdraw")}
                widthFull={true}
                name="Withdraw"
                icon={<Back />}
              />
              <RightTextButton
                onClick={() => setOpenModal("Transfer")}
                widthFull={true}
                name="Transfer"
                icon={<Back />}
                bgColor="bg-darkBlue"
              />
            </div>
          </div>
        ) : (
          <AccountCardSkeleton />
        )}
        <div className="w-full overflow-hidden flex flex-col items-start gap-2">
          {data ? (
            <TabsComponent
              tabList={["Open Positions", "Pending Orders", "Closed Orders"]}
              onClickTab={(name) => setSelected(name)}
              selected={selected}
            />
          ) : (
            <TabsSkeleton />
          )}
          <div className="w-full overflow-auto flex flex-col items-start gap-2">
            {tableData ? (
              <TableComponent keys={accountTableKeys} data={tableData} />
            ) : (
              <TableSkeleton />
            )}
          </div>
        </div>
      </div>
      {
        <ModalComponent
          open={openModal === "Deposit" ? true : false}
          onClose={handleClose}
        >
          <DepositComponent
            code={openModal || "title"}
            title="USD - 33334802"
            handleClose={handleClose}
          />
        </ModalComponent>
      }
      {
        <ModalComponent
          open={openModal === "Withdraw" ? true : false}
          onClose={handleClose}
        >
          <WithdrawComponent
            code={openModal || "title"}
            title="USD - 33334802"
            handleClose={handleClose}
          />
        </ModalComponent>
      }
      {
        <ModalComponent
          open={openModal === "Transfer" ? true : false}
          onClose={handleClose}
        >
          <TransferComponent
            code={openModal || "title"}
            title="USD - 33334802"
            handleClose={handleClose}
          />
        </ModalComponent>
      }
    </div>
  );
};

export default Account;
