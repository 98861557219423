import { FunctionComponent } from "react";
import Icon from "../icon";
import { TextField } from "@mui/material";
import { UseFormRegister } from "react-hook-form";

interface NoteComponentPropsInterface {
  name: string;
  title: string;
  icon: JSX.Element;
  handleChange?: (value: string) => void;
  register?: UseFormRegister<any>;
  required?: boolean;
  error?: boolean;
  widthFull?: boolean;
  className?: string;
}

const NoteComponent: FunctionComponent<NoteComponentPropsInterface> = ({
  name,
  title,
  icon,
  handleChange,
  register,
  required,
  error,
  widthFull,
  className,
}) => {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange && handleChange(e.target.value);
  };

  return (
    <div className={`w-full flex flex-col gap-1 ${className}`}>
      <span className="text-text text-sm font-medium ml-9 whitespace-nowrap">{title}</span>
      <div className="w-full flex items-start gap-2">
        <Icon icon={icon} size={24} className="mt-2" />
        <div className={`w-full ${widthFull ? "w-full" : "lg:w-[294px]"}`}>
          <TextField
            id={name}
            variant="outlined"
            multiline
            rows={2}
            type="text"
            className="w-full"
            {...(register
              ? register(name, { required })
              : { onChange: handleInputChange })}
            error={error}
          />
        </div>
      </div>
    </div>
  );
};
export default NoteComponent;
