import { FC } from "react";
import { CheckBox, SelectedCheckBox } from "../svgIcons";
import Icon from "../icon";

interface PasswordCheckProps {
  passwordRules: { [key: string]: any };
}

const PasswordCheckComponet: FC<PasswordCheckProps> = ({ passwordRules }) => {
  const passwordCheckKeys = [
    { key: "digits", title: "Minimum 8 digits" },
    { key: "lowercase", title: "Lowercase letter" },
    { key: "capital", title: "Capital letter" },
    { key: "number", title: "Include Number" },
    { key: "special", title: "Special Characte" },
  ];

  return (
    <div className="w-full grid grid-cols-2 justify-items-start sm:pl-6">
      {passwordCheckKeys.map((checkKey) => (
        <div className="flex items-center" key={checkKey.key}>
          <Icon
            icon={passwordRules[checkKey.key] ? <SelectedCheckBox /> : <CheckBox />}
            size={24}
          />
          <span className="text-tiny md:text-xs font-normal whitespace-nowrap">{checkKey.title}</span>
        </div>
      ))}
    </div>
  );
};
export default PasswordCheckComponet;
