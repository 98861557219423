import { UseFormRegister } from "react-hook-form";
import { FunctionComponent } from "react";
import { IFormInputs } from "./walletBaseComponent";
import BankWireDepositForm from "./bankWireDepositForm";
import CryptoPayDepositFormComponent from "./cryptoPayDepositFormComponent";
interface ChildComponentProps {
  register: UseFormRegister<IFormInputs>;
  method: string;
  cancle: () => void;
}

const DepositComponent: FunctionComponent<ChildComponentProps> = ({
  register,
  method,
  cancle,
}) => {
  return (
    <div className="flex flex-col justify-center gap-6">
      <div className="w-full text-left py-4">
        <span className="text-primary text-base font-normal">
          Current Balance: $900
        </span>
      </div>
      {method === "10" && <BankWireDepositForm register={register} cancel={cancle} />}
      {method === "20" && <CryptoPayDepositFormComponent register={register} />}
    </div>
  );
};

export default DepositComponent;
