import {FunctionComponent} from 'react';
import cmsColors from "@types.d/CmsColors";

export interface IconProps {
    size:number;
    color?:cmsColors;
    icon:JSX.Element;
    className?: string;
    onClick?: () => void
}

const Icon:FunctionComponent<IconProps> = ({size,color, icon, className, onClick}) => {

    return (
        <div
            className={`flex justify-center items-center cms-fill-${color} ${className}`}
            style={{width:size, height:size}}
            onClick={onClick}
        >
            {icon}
        </div>
    );
};

export default Icon;