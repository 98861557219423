import { FunctionComponent, JSXElementConstructor, useEffect, useState } from "react";
import ModalHeader from "../../components/common/basics/modalHeader";
import TableComponent from "../account/tableComponent";
import { InputAdornment, TextField } from "@mui/material";
import { SearchIcon } from "../../components/common/svgIcons";
import {
  bankAccountTestData,
  bankAccountsKeys,
} from "../../constants/bankAccountsTableTest";
import TableSkeleton from "../../components/common/skeletons/tableSkeleton";

interface BankAccountComponentProps {
  handleClose: () => void;
  handleOpenNewBank: () => void;
}

const BankAccountsComponent: FunctionComponent<BankAccountComponentProps> = (
  props
) => {
  const [bankAccountData, setBankAccountData] = useState<{
    bank_name: string;
    beneficiary_name: string;
    beneficiary_account_number: number;
    actions: JSX.Element;
  }[]>();

  useEffect(() => {
    setTimeout(() => {setBankAccountData(bankAccountTestData)}, 1000)
  }, [])

  return (
    <div className="w-full lg:w-fit flex flex-col items-center p-1 lg:p-3 gap-6">
      <div className="w-full flex flex-col lg:flex-row justify-center items-start lg:justify-start lg:items-center">
        <ModalHeader
          title="Bank Accounts"
          buttonName="Back to Wallet"
          buttonNameTwo="New Bank"
          onClickButton={props.handleClose}
          onClickButtonTwo={props.handleOpenNewBank}
        />
        <div className="w-full lg:w-[294px] ">
          <TextField
            id="search-box"
            placeholder="Search in table"
            variant="outlined"
            className="w-full"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            // error={errors.amount ? true : false}
          />
        </div>
      </div>
      <div className="w-full lg:w-[920px] h-[230px] lg:h-[336px] overflow-y-auto">
        {bankAccountData ?
          <TableComponent data={bankAccountData} keys={bankAccountsKeys} /> : <TableSkeleton />}
      </div>
    </div>
  );
};
export default BankAccountsComponent;
