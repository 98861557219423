import Icon from "../../components/common/icon";
import { CoppyIcon } from "../../components/common/svgIcons";
import { useState } from "react";

const CryptoFinalize = () => {
  const [copied, setCopied] = useState(false);
  const textToCopy = "TCRg6RZQZYghnDFuvF×KVHKAv5ZBLQMFxs";
  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className="flex flex-col justify-center items-center gap-6">
      <span className="text-text text-base font-normal">
        Pay to this wallet address:
      </span>
      <div className="flex flex-col-reverse md:flex-row justify-center items-center gap-2.5">
        <div
          className="bg-primary p-2 rounded-md cursor-pointer"
          onClick={handleCopy}
        >
          <Icon icon={<CoppyIcon />} size={17.5} />
        </div>
        <span className="text-primary text-base font-semibold">
          {textToCopy}
        </span>
      </div>
      <img src="images/barcode.png" alt="barcode"/>
      <div className="w-full grid grid-cols-2 gap-8 lg:flex justify-between items-center border border-borders/50 rounded-md p-2 ">
        <div className="flex flex-col justify-center items-center">
          <span className="text-primary text-base font-medium">Finished!</span>
          <span className="text-text text-base font-medium">Payment Status</span>
        </div>
        <div className="flex flex-col justify-center items-center">
          <span className="text-primary text-base font-medium">100</span>
          <span className="text-text text-base font-medium">Price Amount</span>
        </div>
        <div className="flex flex-col justify-center items-center">
          <span className="text-primary text-base font-medium">100</span>
          <span className="text-text text-base font-medium">Pay Amount</span>
        </div>
        <div className="flex flex-col justify-center items-center">
          <span className="text-primary text-base font-medium">USDTTRC20</span>
          <span className="text-text text-base font-medium">Pay currency</span>
        </div>
        <div className="flex flex-col justify-center items-center">
          <span className="text-primary text-base font-medium">4501146318</span>
          <span className="text-text text-base font-medium">Payment ID</span>
        </div>
      </div>
    </div>
  );
};
export default CryptoFinalize;
