import {
  CorrectionMark,
} from "../../components/common/svgIcons";
import { FunctionComponent, useState } from "react";
import RightTextButton from "../../components/common/basics/rightTextButton";
import BorderedButton from "../../components/common/basics/borderedButton";
import RadioButtonQuestionComponent from "../../components/common/basics/radioButtonQuestionComponent";

interface FinacialQuestionsFormPropsInterface {
  handelNextStep: () => void;
  handelLastStep: () => void;
}

const FinacialQuestionsForm: FunctionComponent<
  FinacialQuestionsFormPropsInterface
> = ({ handelNextStep, handelLastStep }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full flex flex-col gap-6">
      <RadioButtonQuestionComponent
        question="Are you a US Citizen or US resident for tax purpose?"
        handleSelectedChoice={() => {}}
      />
      <RadioButtonQuestionComponent
        question="Are you, a member of your family or close associate 
        a politically exposed person?"
        handleSelectedChoice={() => {}}
      />
      <RadioButtonQuestionComponent
        question="Have you ever declared bankruptcy?"
        handleSelectedChoice={() => {}}
      />
      <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
      <div className="flex gap-2 p-4 w-full md:w-72">
        <BorderedButton
          name="back"
          className="py-2 px-2"
          onClick={handelLastStep}
        />
        <RightTextButton
          name="save"
          icon={<CorrectionMark />}
          onClick={handelNextStep}
        />
      </div>
    </div>
  );
};
export default FinacialQuestionsForm;
