import { useState, useEffect } from "react";
import Icon from "../icon";
import { ArrowDown, MenuButton, MenuButtonOpen } from "../svgIcons";
import { MenuItems } from "../../../constants/menuItems";
import BorderedButton from "./borderedButton";
import { useHistory } from "react-router-dom";

const MenuNav = () => {
  const [selected, setSelected] = useState<string>("");
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [showSpan, setShowSpan] = useState<boolean>(false);
  const [openSubMenu, setOpenSubMenu] = useState<string | null>();
  const history = useHistory();

  useEffect(() => {
    const path = history.location.pathname.split("/")[1];
    // let finalPath = ''
    // path === 'my-fund' ? finalPath : finalPath = path
    setSelected(`/${path}`);
  }, []);

  useEffect(() => {
    if (openMenu) {
      const timeout = setTimeout(() => {
        setShowSpan(true);
      }, 200);

      return () => clearTimeout(timeout);
    } else {
      setShowSpan(false);
    }
  }, [openMenu]);

  return (
    <div
      id="parent"
      // style={{ minHeight: "calc(100vh - 65px)" }}
      className={`hidden min-h-screen lg:flex flex-col justify-between p-2 bg-light-blue `}
    >
      <div
        className={`flex flex-col items-center transition-all duration-300 ease-in-out ${
          openMenu ? "w-56" : "w-12"
        }`}
      >
        <div
          className="w-full flex justify-center border-b border-b-borders/50 cursor-pointer"
          onClick={() => {
            setOpenMenu(!openMenu);
            setOpenSubMenu(null);
          }}
        >
          <Icon
            icon={openMenu ? <MenuButtonOpen /> : <MenuButton />}
            size={48}
          />
        </div>
        {MenuItems.map((menuItem, index) => (
          <div
            className={`w-full mt-2 cursor-pointer px-2 rounded-md  ${
              menuItem?.items ? "" : "hover:bg-light-press-blue"
            } ${
              menuItem?.items &&
              menuItem?.items.some((item) => item.address === selected)
                ? "bg-light-press-blue"
                : ""
            } ${
              selected === menuItem.address && !menuItem?.items
                ? "text-white bg-primary"
                : "text-primary bg-light-hover-blue"
            }`}
          >
            <div
              key={index}
              className={`w-full flex items-center gap-2 relative py-2 ${
                openSubMenu === menuItem.name
                  ? "border-b border-b-borders/50"
                  : ""
              }`}
              onClick={() =>
                menuItem?.items
                  ? openSubMenu === menuItem.name
                    ? (setOpenSubMenu(null), setOpenMenu(true))
                    : (setOpenSubMenu(menuItem.name), setOpenMenu(true))
                  : (setSelected(menuItem.address),
                    history.push(menuItem.address))
              }
            >
              <Icon icon={menuItem.icon} size={32} />
              <span
                className={`whitespace-nowrap  ${
                  openMenu ? "visible delay-150" : "invisible"
                }`}
              >
                {menuItem.name}
              </span>
              {menuItem?.items && (
                <span
                  className={`absolute right-4 ${
                    openMenu ? "visible delay-150" : "invisible"
                  }`}
                >
                  <Icon icon={<ArrowDown />} size={15} />
                </span>
              )}
            </div>
            {menuItem?.items && openSubMenu === menuItem.name && openMenu && (
              <div className="flex flex-col gap-2 py-2">
                {menuItem.items.map((subItem, index) => (
                  <div
                    key={index}
                    className={`w-full flex items-center gap-2 cursor-pointer`}
                    onClick={() => {
                      setSelected(subItem.address);
                      history.push(subItem.address);
                    }}
                  >
                    <div
                      className={`w-full flex gap-2 items-center ml-4 p-2 ${
                        menuItem?.items &&
                        menuItem?.items.some(
                          (item) => item.address === selected
                        )
                          ? "hover:bg-light-hover-blue hover:rounded-md"
                          : "hover:bg-light-press-blue hover:rounded-md"
                      } ${
                        index < menuItem.items.length - 1
                          ? "border-b border-b-borders/50"
                          : ""
                      } ${
                        selected === subItem.address
                          ? "text-white bg-primary rounded-md"
                          : "text-text"
                      } `}
                    >
                      <Icon icon={subItem.icon} size={32} />
                      <span
                        className={`whitespace-nowrap  ${
                          showSpan ? "visible" : "invisible"
                        }`}
                      >
                        {subItem.name}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {openMenu ? (
        <div className="w-full flex flex-col items-start py-3 border-t border-t-borders/50 relative">
          <div className="w-full h-2 bg-light-hover-blue rounded-full"></div>
          <div className="absolute top-3 left-0 w-4 h-2 bg-primary rounded-l-full">
            <div className="absolute mt-2 p-1 bg-primary rounded-full text-white flex justify-center items-center">
              4%
            </div>
          </div>
          <BorderedButton
            name="Verify Now!"
            className="py-6 mt-12 bg-light-blue"
            onClick={() => history.push('verification')}
          />
        </div>
      ) : (
        <div className="w-full flex justify-center items-center py-3 border-t border-t-borders/50 relative">
          <div className="w-12 h-12 flex justify-center items-center border-4 border-light-hover-blue rounded-full">
            4%
          </div>
          <div className="absolute top-3 left-0 w-5 h-5 border-t-4 border-l-4 border-primary rounded-tl-full"></div>
        </div>
      )}
    </div>
  );
};

export default MenuNav;
