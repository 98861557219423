import { FunctionComponent } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";
import UnstyledPaginationIntroduction from "../../components/common/basics/tablePagination";
import Icon from "../../components/common/icon";
import { UpDownButton } from "../../components/common/svgIcons";
import { colorObject } from "../../constants/historyTableTest ";

interface TableComponentProps {
  data?: { [key: string]: any }[];
  keys: string[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(14, 46, 227, 0.1)",
    color: "#020617",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EAEAEA",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableComponent: FunctionComponent<TableComponentProps> = (props) => {
  const colorFunction = (value: string) => {
    return colorObject[value];
  };

  return (
    <div className="flex flex-col gap-2">
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700, maxWidth: "100%" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {props.keys.map((key, index) => (
                <StyledTableCell
                  className="border-r border-r-borders/50"
                  align="center"
                  key={key + index}
                >
                  <div className="flex justify-center items-center gap-1">
                    <Icon icon={<UpDownButton />} size={16} />
                    {key}
                  </div>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ maxHeight: 200, overflow: "auto" }}>
            {props?.data?.map((row, index) => (
              <StyledTableRow key={index}>
                {Object.entries(row).map(([key, value]) => (
                  <StyledTableCell
                    className="border-r border-r-borders/50"
                    component="th"
                    scope="row"
                    key={key}
                  >
                    <span
                      className={`${
                        value in colorObject
                          ? `${colorFunction(value)} text-white rounded p-0.5`
                          : ""
                      }`}
                    >
                      {value}
                    </span>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UnstyledPaginationIntroduction count={props?.data?.length || 0} />
    </div>
  );
};

export default TableComponent;
