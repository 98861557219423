import { FilterIcon } from "../../components/common/svgIcons";
import IbLayout from "../../components/layouts/ibLayout";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import TableComponent from "../account/tableComponent";
import { ibBreakDownReportTableKeys } from "../../constants/ibTablesData";
import DatePickerComponent from "../../components/common/basics/datePickerComponent";
import { CalendarIcon } from "@mui/x-date-pickers";
import { useState } from "react";
import IbPagesInnerLayout from "../../components/layouts/ibPagesInnerLayout ";

const IbBreakDownReport = () => {
  const [openFilterNav, setOpenFilterNav] = useState<boolean>(true);

  return (
    <IbPagesInnerLayout
      buttonName="Export Breakdown"
      handleClickButton={() => {}}
      handleSwitchFilters={() => setOpenFilterNav(!openFilterNav)}
    >
      <div className="w-full flex flex-col justify-start items-start">
        <IbLayout title="Breakdown Report" openFilterNav={openFilterNav} largeFilter>
          <div className="w-full md:w-auto flex flex-col md:flex-row gap-2 justify-center items-center">
            <DatePickerComponent
              name="date"
              icon={<CalendarIcon />}
              handleChange={() => {}}
              type="text"
            />
            <DropDownComponent
              name="columns"
              icon={<FilterIcon className="text-text" />}
              selectList={testList}
              handleSelect={() => {}}
              placeHolder="All Columns Selected "
              width="w-full lg:w-[150px]"
            />
          </div>
        </IbLayout>
        <div className="w-full overflow-x-auto sc-sp2 flex flex-col justify-start items-start">
          <TableComponent keys={ibBreakDownReportTableKeys} />
          <TableComponent keys={ibBreakDownReportTableKeys} />
        </div>
      </div>
    </IbPagesInnerLayout>
  );
};

export default IbBreakDownReport;
