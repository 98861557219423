import Box from "@mui/material/Box";
import { LinearProgress } from "@mui/material";
import { FunctionComponent } from "react";
import Icon from "../icon";
import { NextStepIcon } from "../svgIcons";
import { verificationStepperInterface } from "../../../constants/verificationSteppers";

interface StepperPropsInterface {
  activeStep: number;
  steps: verificationStepperInterface[];
}
const StepperComponent: FunctionComponent<StepperPropsInterface> = ({
  activeStep,
  steps,
}) => {

  const theNumber = (100 / steps.length) * activeStep;

  return (
    <div className="w-full md:max-w-fit flex flex-col gap-2 pb-8 relative overflow-hidden">
      <div
        className={`absolute md:relative top-0 left-0 ${
          steps.length > 3 &&
          (activeStep === 1
            ? "left-[-100px]"
            : activeStep >= 2
            ? "left-[-150px]"
            // : activeStep === 3
            // ? "left-[-200px]"
            : "left-0")
        } md:left-0 flex `}
      >
        {steps.map((label, index) => (
          <div
            key={label.name}
            className={`flex gap-4 ${
              index <= activeStep ? "text-primary" : "text-black"
            }`}
          >
            {index > 0 && <Icon icon={<NextStepIcon />} size={24} />}
            <div
              className={`flex flex-col gap-4 justify-center items-center ${
                index <= activeStep ? "text-primary" : "text-text"
              }`}
            >
              <Icon icon={label.icon} size={24} />
              <div className="flex flex-col items-center">
                <span className="text-tiny md:text-xs font-normal whitespace-nowrap">
                  {label.name}
                </span>
                <span className="text-tiny md:text-xs font-normal">
                  {label.required ? "Required*" : <br />}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full mt-24 md:mt-0 relative">
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={(100 / steps.length) * activeStep}
          />
        </Box>
        <div
          className={`absolute top-2 px-1 text-white bg-primary rounded-md `} style={{left: `calc(${theNumber}%)`}}
        >
          {Math.round((100 / steps.length) * activeStep)}
        </div>
      </div>
      {/* <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        
      </Box> */}
    </div>
  );
};
export default StepperComponent;
