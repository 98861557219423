import React, { FunctionComponent } from "react";
import Icon from "../common/icon";

export interface PasswordValidationLayoutProps {
  children?: React.ReactNode;
  titleName: string;
  logo: JSX.Element;
}
const PasswordValidationLayout: FunctionComponent<PasswordValidationLayoutProps> = (props) => {
  return (
    <div className="w-full flex flex-col gap-2.5 justify-center items-center ">
      <Icon icon={props.logo} size={48} />
      <span className="text-text text-[22px] font-medium">{props.titleName}</span>
      {props.children}
    </div>
  );
};

export default PasswordValidationLayout;
