import { FC } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import { formatString } from "../../../functions/formString";

const Label = (props: any) => {
  const { x, y, value } = props;

  return (
    <text
      x={x}
      y={y}
      //   dx={"2%"}
      dy={"-1%"}
      fontSize="15"
      fontWeight="bold"
      fill={"#181818"}
      textAnchor="left"
    >
      {value}
    </text>
  );
};

export interface BarChartProps {
  data: { name: string; deposit: number | null; withdraw: number | null }[];
  dataKeys: { name: string; color: string }[];
}

const BarChartComponent: FC<BarChartProps> = ({ data, dataKeys }) => {
  return (
    <div className="w-full lg:w-auto relative flex justify-center items-center mt-6 ">
      <ResponsiveContainer width="100%" height={330} aspect={1}>
        <BarChart data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend
            content={
              <div className="w-full flex justify-center items-center gap-10 mt-6">
                {dataKeys.map((key, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center gap-2"
                  >
                    <div
                      className="h-6 w-1 rounded-md"
                      style={{ backgroundColor: key.color }}
                    />
                    <span className="text-base font-normal text-text">
                      {formatString(key.name)}
                    </span>
                  </div>
                ))}
              </div>
            }
            // formatter={(value, index) => <span>{formatString(value)}</span>}
          />
          {dataKeys.map((dataItem, index) => (
            <Bar
              key={index}
              dataKey={dataItem.name}
              label={<Label />}
              fill={dataItem.color}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
