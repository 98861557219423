

export const passwordAnalyze = (passwordRules: { [key: string]: boolean }, passwordValue: string) => {
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    passwordRules.digits = passwordValue.length >= 8;
    passwordRules.lowercase = lowercaseRegex.test(passwordValue);
    passwordRules.capital = uppercaseRegex.test(passwordValue);
    passwordRules.number = numberRegex.test(passwordValue);
    passwordRules.special = specialCharRegex.test(passwordValue);
    
    if (
        passwordValue.length >= 8 &&
        lowercaseRegex.test(passwordValue) &&
        uppercaseRegex.test(passwordValue) &&
        numberRegex.test(passwordValue) &&
        specialCharRegex.test(passwordValue)
    ) {
        return true
    } else {
        return false
    }
}
