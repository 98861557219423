import {
  CalendarIcon,
  CorrectionMark,
  GlobeIcon,
  UserIcon,
} from "../../components/common/svgIcons";
import { FunctionComponent, useState } from "react";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import InputComponent from "../../components/common/basics/inputComponent";
import DatePickerComponent from "../../components/common/basics/datePickerComponent";
import PhoneComponent from "../../components/common/basics/phoneComponent";
import RightTextButton from "../../components/common/basics/rightTextButton";

interface PrsonDetailPropsInterface {
  handelNextStep: () => void;
}

const PersonalDetailForm: FunctionComponent<PrsonDetailPropsInterface> = ({
  handelNextStep,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full md:w-auto flex flex-col md:grid md:grid-cols-2 gap-6">
      <DropDownComponent
        title="Salutation"
        name="Salutation"
        icon={<UserIcon />}
        selectList={testList}
        handleSelect={() => {}}
        widthFull
      />
      <DropDownComponent
        title="Martial Status"
        name="martial_tatus"
        icon={<UserIcon />}
        selectList={testList}
        handleSelect={() => {}}
        widthFull
      />
      <InputComponent
        title="First Name"
        name="first_name"
        icon={<UserIcon />}
        handleChange={() => {}}
        type="text"
      />
      <InputComponent
        title="Last Name"
        name="last_name"
        icon={<UserIcon />}
        handleChange={() => {}}
        type="text"
      />
      <InputComponent
        title="Middle Name"
        name="middle_name"
        icon={<UserIcon />}
        handleChange={() => {}}
        type="text"
      />
      <DropDownComponent
        title="Nationality"
        name="nationality"
        icon={<GlobeIcon />}
        selectList={testList}
        handleSelect={() => {}}
        widthFull
      />
      <DropDownComponent
        title="Country of Birth"
        name="cuntry_of_birth"
        icon={<GlobeIcon />}
        selectList={testList}
        handleSelect={() => {}}
        widthFull
      />
      <DatePickerComponent
        title="Middle Name"
        name="middle_name"
        icon={<CalendarIcon />}
        handleChange={() => {}}
        widthFull
        type="text"
      />
      <PhoneComponent
        handleChange={(val) => {}} 
        // error={null}
      />
      <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
      <div className="flex gap-2 p-4 w-full md:w-72">
        <RightTextButton
          name="save"
          icon={<CorrectionMark />}
          onClick={handelNextStep}
        />
      </div>
    </div>
  );
};
export default PersonalDetailForm;
