import { useEffect, useState } from "react";
import { DownloadIcon } from "../../components/common/svgIcons";
import Icon from "../../components/common/icon";
import BarChartComponent, {
  BarChartProps,
} from "../../components/common/basics/barChartComponent";
import LineChartSkeleton from "../../components/common/skeletons/lineChartSkeleton";

const DepositWithdrawalComponent = () => {
  const [chartData, setChartData] = useState<BarChartProps["data"]>();
  const [dataKeys, setDataKeys] = useState<BarChartProps["dataKeys"]>();

  useEffect(() => {
    setTimeout(() => {
      setChartData([
        { name: "Jan", deposit: 10, withdraw: 15 },
        { name: "Feb", deposit: 20, withdraw: null },
        { name: "Mar", deposit: 15, withdraw: 16 },
        { name: "Apr", deposit: 25, withdraw: null },
        { name: "May", deposit: 30, withdraw: 28 },
        { name: "Jun", deposit: 20, withdraw: 24 },
        { name: "Jul", deposit: null, withdraw: 26 },
        { name: "Aug", deposit: 30, withdraw: 28 },
        { name: "Sep", deposit: 20, withdraw: 24 },
        { name: "Oct", deposit: null, withdraw: 26 },
        { name: "Nov", deposit: 30, withdraw: 28 },
        { name: "Dec", deposit: 40, withdraw: null },
      ]);
    }, 1000);
    setDataKeys([
      { name: "deposit", color: "#0E2EE3" },
      { name: "withdraw", color: "#FF5630" },
    ]);
  }, []);

  return (
    <div className="w-full lg:w-[492px] lg:h-[300px] flex flex-col justify-start items-start gap-4 bg-white">
      <span className="text-2xl font-medium">Deposit & Withdrawals</span>
      <div className="w-full flex flex-col justify-start items-start gap-6 p-6 rounded-md border border-borders/50 relative">
        <div className="absolute top-6 right-6 p-3 z-10 rounded-md bg-darkBlue text-center">
          <Icon icon={<DownloadIcon />} size={20} />
        </div>
        {chartData ? (
          dataKeys && <BarChartComponent data={chartData} dataKeys={dataKeys} />
        ) : (
          <LineChartSkeleton />
        )}
      </div>
    </div>
  );
};
export default DepositWithdrawalComponent;
