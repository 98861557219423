import { FunctionComponent, useState } from "react";
import Icon from "../icon";
import { Mobile } from "../svgIcons";
import PhoneInput from "react-phone-input-2";

interface PhonePropsInterface {
  handleChange: (value: string) => void;
  error?: boolean;
  widthFull?: boolean;
  className?: string;
}

const PhoneComponent: FunctionComponent<PhonePropsInterface> = (props) => {
  const [phone, setPhone] = useState<string>("98");

  const handleValidation = () => {};

  return (
    <div className="w-full flex flex-col gap-2 ">
      <span className="text-text text-sm font-medium ml-9 whitespace-nowrap">Phone Number</span>
      <div className={`w-full  ${props.widthFull ? 'w-full' : 'lg:w-[294px]'} ${props.className} flex items-center gap-2`}>
        <Icon icon={<Mobile />} size={24} />
        <>
          <PhoneInput
            placeholder="Enter phone number"
            value={phone}
            onChange={props.handleChange}
            inputStyle={{
              height: "44px",
              width: "100%",
              borderColor: `${props?.error ? "red" : ""}`,
            }}
            onBlur={handleValidation}
          />
          {/* {error && <span>{error}</span>} */}
        </>
      </div>
    </div>
  );
};
export default PhoneComponent;
