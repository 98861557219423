import { FunctionComponent } from "react";
import Icon from "../icon";
import { TextField } from "@mui/material";
import { UseFormRegister } from "react-hook-form";

interface InputComponentPropsInterface {
  name: string;
  title: string;
  icon: JSX.Element;
  type: string;
  handleChange?: (value: string) => void;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  required?: boolean;
  error?: boolean;
  widthFull?: boolean;
  className?: string;
}

const InputComponent: FunctionComponent<InputComponentPropsInterface> = ({
  name,
  title,
  icon,
  type,
  handleChange,
  disabled,
  register,
  required,
  error,
  widthFull,
  className,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange && handleChange(e.target.value);
  };

  return (
    <div className="w-full flex flex-col gap-1">
      <span className={`${disabled ? "text-borders" : "text-text"} text-sm font-medium ml-9 whitespace-nowrap`}>
        {title}
      </span>
      <div className="w-full flex items-center gap-2">
        <Icon icon={icon} size={24} />
        <div
          className={`w-full ${
            widthFull ? "w-full" : "lg:w-[294px]"
          } ${className}`}
        >
          <TextField
            disabled={disabled}
            id={name}
            type={type}
            variant="outlined"
            InputProps={{
              style: { height: 44 },
            }}
            className="w-full"
            {...(register
              ? register(name, { required })
              : { onChange: handleInputChange })}
            error={error}
          />
        </div>
      </div>
    </div>
  );
};
export default InputComponent;
