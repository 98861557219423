import RightTextButton from "../../components/common/basics/rightTextButton";
import {
  CheckMark,
  Currency,
  Note,
} from "../../components/common/svgIcons";
import { useForm } from "react-hook-form";
import ModalHeader from "../../components/common/basics/modalHeader";
import { FunctionComponent } from "react";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import InputComponent from "../../components/common/basics/inputComponent";
import NoteComponent from "../../components/common/basics/noteComponent";

interface IFormInputs {
  wallet: string;
  amount: string;
  note: string;
}

interface DepositComponentProps {
  handleClose: () => void;
  title: string;
  code: string;
}

const DepositComponent: FunctionComponent<DepositComponentProps> = (
  props
) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({});

  return (
    <div className="w-full flex flex-col gap-4 p-2 bg-white relative">
      <ModalHeader
        title={props.title}
        name={props.code}
        buttonName="Back to Accounts"
        onClickButton={props.handleClose}
      />
      <div className="w-full text-left py-4">
        <span className="text-primary text-base font-normal">
          Current Balance: $900
        </span>
      </div>
      <form className="w-full flex flex-col justify-center lg:grid lg:grid-cols-2 justify-items-start items-center gap-2">
        <DropDownComponent
          name="wallet"
          title="Wallet"
          icon={<Currency className="text-darkBlue" />}
          selectList={testList}
          handleSelect={() => {}}
          register={register}
          required
          error={errors.wallet ? true : false}
          width="w-full lg:w-[294px]"
        />
        <InputComponent
          name="amount"
          title="Amount in $"
          icon={<Currency className="text-darkBlue" />}
          type="text"
          // handleChange={(value) => setValue("amount", value)}
          register={register}
          required
          error={errors.amount ? true : false}
          widthFull={true}
        />
        <NoteComponent
          name="note"
          title="Note"
          icon={<Note className="text-darkBlue" />}
          register={register}
          required
          error={errors.amount ? true : false}
          widthFull={true}
        />
        <div className="w-full flex gap-2 col-span-2 mt-4">
          <RightTextButton
            name="Cancel"
            bgColor="white"
            textColor="primary"
            className="bg-white border border-borders/50 text-primary"
            onClick={props.handleClose}
          />
          <RightTextButton name={props.code} icon={<CheckMark />} />
        </div>
      </form>
    </div>
  );
};
export default DepositComponent;
