import RightTextButton from "../../components/common/basics/rightTextButton";
import StepperComponent from "../../components/common/basics/stepperComponent";
import { CorrectionMark } from "../../components/common/svgIcons";
import { verificationFirstStepper } from "../../constants/verificationSteppers";
import { useHistory } from "react-router-dom";

const VerificationCard = () => {
  const history = useHistory();

  return (
    <div className="w-full md:w-auto lg:max-w-fit flex flex-col justify-center items-end gap-6 p-6 rounded-md border border-borders/50">
      <StepperComponent steps={verificationFirstStepper} activeStep={0} />
      <RightTextButton
        name="Continue Verification"
        icon={<CorrectionMark />}
        widthFull={false}
        onClick={() => history.push("/verification")}
      />
    </div>
  );
};
export default VerificationCard;
