import React, { FunctionComponent, useEffect, useState } from "react";
import MenuNav from "../common/basics/menuNav";
import MenuNavMobile from "../common/basics/menuNavMobile";
import Header from "../common/basics/Header";

export interface BaseLayoutProps {
  children?: React.ReactNode;
  title: string;
  withNav: boolean;
}
const BaseLayout: FunctionComponent<BaseLayoutProps> = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]); 

  return (
    <div className={`w-full overflow-hidden flex flex-col bg-white relative`}>
      <Header title={props.title} />
      <div className="w-full h-full flex">
        { width >= 1024 && <MenuNav />}
        <div
          className={`w-full h-full flex p-4 mb-16 lg:mb-0 overflow-x-hidden overflow-y-auto sc-sp2 `}
        >
          {props.children}
        </div>
      </div>
      {(props.withNav && width < 1024) && <MenuNavMobile />}
    </div>
  );
};

export default BaseLayout;
