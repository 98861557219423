import { FunctionComponent, useState } from "react";
import ModalHeader from "../../components/common/basics/modalHeader";
import {
  AccountIcon,
  BankIcon,
  Hashtag,
  LocationIcon,
  NoteIcon,
  Plus,
} from "../../components/common/svgIcons";
import { useForm } from "react-hook-form";
import BorderedButton from "../../components/common/basics/borderedButton";
import SubmitButton from "../../components/common/basics/submitButton";
import InputComponent from "../../components/common/basics/inputComponent";
import NoteComponent from "../../components/common/basics/noteComponent";

interface TransactionComponentProps {
  handleClose: () => void;
}

const AddNewBank: FunctionComponent<TransactionComponentProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    getValues,
    // formState: { },
  } = useForm({});

  const onSubmitNewBankForm = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <div className="w-full lg:w-fit  h-[400px] lg:h-auto overflow-auto flex flex-col md:items-center p-1 lg:p-3 gap-6">
      <ModalHeader
        title="Define New Bank"
        // name={props.code}
        buttonName="Back to Wallet"
        onClickButton={props.handleClose}
      />

      <form className="flex flex-col justify-center md:grid md:grid-cols-2 gap-6">
        <InputComponent
          name="bank_name"
          title="Bank Name"
          icon={<BankIcon className="text-darkBlue" />}
          type="text"
          // handleChange={(value) => setValue("bank_name", value)}
          // register={register}
          required
          // error={errors.bank_name ? true : false}
          widthFull
        />
        <div/>
        <InputComponent
          name="bank_address"
          title="Bank Address"
          icon={<LocationIcon className="text-darkBlue" />}
          type="text"
          // handleChange={(value) => setValue("bank_address", value)}
          // register={register}
          required
          // error={errors.bank_address ? true : false}
          widthFull
        />
        <InputComponent
          name="beneficiary_name"
          title="Beneficiary Name"
          icon={<AccountIcon className="text-darkBlue" />}
          type="text"
          // handleChange={(value) => setValue("beneficiary_name", value)}
          // register={register}
          required
          // error={errors.beneficiary_name ? true : false}
          widthFull
        />
        <InputComponent
          name="beneficiary_account_number"
          title="Beneficiary Account Number"
          icon={<Hashtag className="text-darkBlue" />}
          type="text"
          // handleChange={(value) => setValue("beneficiary_account_number", value)}
          // register={register}
          required
          // error={errors.beneficiary_account_number ? true : false}
          widthFull
        />
        <InputComponent
          name="beneficiary_address"
          title="Beneficiary Address"
          icon={<LocationIcon className="text-darkBlue" />}
          type="text"
          // handleChange={(value) => setValue("beneficiary_address", value)}
          // register={register}
          required
          // error={errors.beneficiary_address ? true : false}
          widthFull
        />
        <InputComponent
          name="swift_code"
          title="Swift/IFSC Code"
          icon={<Hashtag className="text-darkBlue" />}
          type="text"
          // handleChange={(value) => setValue("swift_code", value)}
          // register={register}
          required
          // error={errors.swift_code ? true : false}
          widthFull
        />
        <InputComponent
          name="iban"
          title="IBAN"
          icon={<Hashtag className="text-darkBlue" />}
          type="text"
          // handleChange={(value) => setValue("iban", value)}
          // register={register}
          required
          // error={errors.iban ? true : false}
          widthFull
        />
        <NoteComponent
          name="note"
          title="Note"
          icon={<NoteIcon className="text-darkBlue" />}
          // handleChange={(value) => setValue("note", value)}
          // register={register}
          required
          // error={errors.note ? true : false}
          widthFull
        />
        <div/>
        <div className="col-span-2 flex gap-2 p-4 w-full md:w-72">
          <BorderedButton name="Cancel" />
          <SubmitButton
            widthFull={true}
            loading={loading}
            name="Add New Bank"
            icon={<Plus />}
            onClick={onSubmitNewBankForm}
          />
        </div>
      </form>
    </div>
  );
};
export default AddNewBank;
