import { FC, useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";

export interface DonutChartProps {
  chartData: {
    name: string;
    value: number;
    color: string;
    strokeWidth: number;
  }[];
  onChangeData: (
    id: {
      name: string;
      value: number;
      color: string;
      strokeWidth: number;
    }[]
  ) => void;
}

const DoughnutChartComponent: FC<DonutChartProps> = ({
  chartData,
  onChangeData,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<{
    name: string;
    value: number;
    color: string;
    strokeWidth: number;
  }>();

  useEffect(() => {
    setSelectedIndex(chartData[0])
  }, [])
  const selectedStrokeWidth = 10; // Adjust the border size for the selected section

  const handlePieClick3 = (index: number) => {
    const newData = [...chartData];

    // Update the stroke width of the selected section
    newData.forEach((entry, i) => {
      if (i === index) {
        entry.strokeWidth = selectedStrokeWidth;
      } else {
        entry.strokeWidth = 0;
      }
    });
    // Update the state or props with the new data
    setSelectedIndex(chartData[index]);
    onChangeData(newData);
  };

  const renderCenterLabel = () => (
    <div style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}>
      Center Label
    </div>
  );

  return (
    <div className="relative mt-6">
      <PieChart width={291} height={291}>
        <Pie
          data={chartData}
          dataKey="value"
          nameKey="name"
          innerRadius={130}
          outerRadius={140}
          paddingAngle={5}
        >
          {chartData.map((entry, index) => (
            <Cell
              onClick={() => handlePieClick3(index)}
              key={`cell-${index}`}
              fill={entry.color}
              strokeWidth={entry.strokeWidth}
              stroke={entry.color}
              style={{ outline: "none", cursor: "pointer" }}
            />
          ))}
        </Pie>
        <text
          x={145}
          y={100}
          textAnchor="middle"
          dominantBaseline="middle"
          className=" text-base font-semibold text-text"
          color="blu"
        >
          {selectedIndex?.name}
        </text>
        <text
          x={145}
          y={160}
          textAnchor="middle"
          dominantBaseline="middle"
          className="text-[90px] font-semibold text-text"
        >
          {selectedIndex?.value}%
        </text>
      </PieChart>
    </div>
  );
};

export default DoughnutChartComponent;
