import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { CorrectionMark, Letter, Pen } from "../../components/common/svgIcons";
import SimpleButton from "../../components/common/basics/simpleButton";
import BorderedButton from "../../components/common/basics/borderedButton";
import PasswordValidationLayout from "../../components/layouts/passwordValidationLayout";

export interface EmailVerificationProps {
  handleChangeLevel: () => void;
}

const EmailVerification: FunctionComponent<EmailVerificationProps> = (
  props
) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleChangeOtpInput = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = event.target.value;
      setOtp(newOtp);
      if (event.target.value !== "") {
        if (index < 5) {
          document.getElementById(`otp${index + 1}`)?.focus();
        }
      }
    }
  };

  useEffect(() => {
    const isLengthOne = otp.every((item) => item.length === 1);
    !isLengthOne && setError(false);
  }, [otp]);

  const handleSubmitEmailVerification = () => {
    const isLengthOne = otp.every((item) => item.length === 1);
    isLengthOne ? setError(false) : setError(true);
    if (isLengthOne) {
      setLoading(true);
      setTimeout(() => setLoading(false), 2000);
      props.handleChangeLevel();
    }
  };

  return (
    <PasswordValidationLayout titleName="Email Confirmation" logo={<Letter />}>
      <span className="text-text text-sm font-medium">
        Please check your email (ghavanlooa@gmail.com){" "}
      </span>
      <span className="text-text text-sm font-medium">
        and <span className="text-primary">Submit Confirm Button</span>
      </span>
      <div className="w-full flex items-center relative my-4 ">
        <hr className="bg-borders/50 flex-grow h-0.5" />
        <span className="text-black px-4">OR</span>
        <hr className="bg-borders/50 flex-grow h-0.5" />
      </div>
      <span className="text-text text-sm font-medium">
        Enter the confirmation code sent to{" "}
      </span>
      <span className="text-text text-sm font-medium">
        <span className="italic font-semibold ">ghavanlooa@gmail.com</span>{" "}
        below:{" "}
      </span>
      <div className="flex gap-2 justify-center items-center my-2">
        {otp.map((value, index) => (
          <div
            className={`border flex justify-center items-center w-11 h-11 ${
              error ? "border-error" : "border-borders"
            } rounded-md`}
          >
            <input
              key={index}
              id={`otp${index}`}
              type="text"
              maxLength={1}
              value={value}
              onChange={(e) => handleChangeOtpInput(index, e)}
              className={`border-b ${
                error ? "border-error" : "border-borders"
              } p-2.5 w-8 h-8 text-dark text-lg font-semibold focus:outline-none`}
            />
          </div>
        ))}
      </div>
      <BorderedButton name="Edit Email" icon={<Pen />} className="mt-3 py-6" />
      <SimpleButton
        onClick={handleSubmitEmailVerification}
        name="Verify Confirmation Code"
        icon={<CorrectionMark />}
        loading={loading}
        className="my-3"
      />
    </PasswordValidationLayout>
  );
};

export default EmailVerification;
