import { ChangeEvent, FunctionComponent, useState } from "react";
import BorderedButton from "../../components/common/basics/borderedButton";
import ModalHeader from "../../components/common/basics/modalHeader";
import RightTextButton from "../../components/common/basics/rightTextButton";
import { CorrectionMark } from "../../components/common/svgIcons";

interface ConfigureAuthAppProps {
    handleClose: () => void;
    verified: () => void;
  }

const ConfigureAuthApp: FunctionComponent<ConfigureAuthAppProps> = (props) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState<boolean>(false);

  const handleChangeOtpInput = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = event.target.value;
      setOtp(newOtp);
      if (event.target.value !== "") {
        if (index < 5) {
          document.getElementById(`otp${index + 1}`)?.focus();
        }
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-start gap-6">
      <ModalHeader
        title="Configure 
          Authenticator App"
        // name={props.code}
        buttonName="Back to My Profile"
        onClickButton={props.handleClose}
      />
      <span className="text-text text-sm font-normal">
        To use an authenticator app go through the following steps:
      </span>
      <span className="text-text text-sm font-normal">
        1. Download a two-factor authenticator app like Microsoft Authenticator
        for <a className="text-primary">Android</a> and{" "}
        <a className="text-primary">iOS</a> or Google Authenticator for{" "}
        <a className="text-primary">Android</a> and{" "}
        <a className="text-primary">iOS</a>.
      </span>
      <span className="text-text text-sm font-normal">
        2. Scan the QR Code or enter this key{" "}
        <a className="text-primary">qkdy zfzh uzjn 6buz 532o sepu px7l oagv</a>{" "}
        into your two factor authenticator app. Spaces and casing do not matter.
      </span>
      <BorderedButton name="Learn how to enable QR code generation." />
      <span className="text-text text-sm font-normal">
        3. Once you have scanned the QR code or input the key above, your two
        factor authentication app will provide you with a unique code. Enter the
        code in the confirmation box below.{" "}
      </span>
      <div className="flex flex-col gap-2 justify-center items-start">
        <span className="text-text text-sm font-normal">Verification Code</span>
        <div className="flex gap-2 justify-center items-center my-2">
          {otp.map((value, index) => (
            <div
              className={`border flex justify-center items-center w-11 h-11 ${
                error ? "border-error" : "border-borders"
              } rounded-md`}
            >
              <input
                key={index}
                id={`otp${index}`}
                type="text"
                maxLength={1}
                value={value}
                onChange={(e) => handleChangeOtpInput(index, e)}
                className={`border-b ${
                  error ? "border-error" : "border-borders"
                } p-2.5 w-8 h-8 text-dark text-lg font-semibold focus:outline-none`}
              />
            </div>
          ))}
        </div>
      </div>
      <RightTextButton
          name="Verify"
          icon={<CorrectionMark />}
          className="my-3"
          onClick={props.verified}
        />
    </div>
  );
};
export default ConfigureAuthApp;
