import { FunctionComponent, useEffect, useState } from "react";
import ModalHeader from "../../components/common/basics/modalHeader";
import TableComponent from "../account/tableComponent";
import {
  historyTableKeys,
  historyTableTestData,
} from "../../constants/historyTableTest ";
import { InputAdornment, TextField } from "@mui/material";
import { SearchIcon } from "../../components/common/svgIcons";
import TableSkeleton from "../../components/common/skeletons/tableSkeleton";

interface TransactionComponentProps {
  handleClose: () => void;
}

const TransactionHistory: FunctionComponent<TransactionComponentProps> = (
  props
) => {
  const [historyTableData, setHistoryTableData] = useState<
    {
      transaction_type: string;
      payment_display_name: String;
      request_time: string;
      amount: number;
      currency: string;
      account: number;
      result: string;
    }[]
  >();

  useEffect(() => {
    setTimeout(() => {
      setHistoryTableData(historyTableTestData);
    }, 1000);
  }, []);

  return (
    <div className="w-full lg:w-fit flex flex-col  items-center p-1 lg:p-3 gap-6">
      <div className="w-full flex flex-col lg:flex-row justify-start lg:items-center">
        <ModalHeader
          title="Transaction History"
          // name={props.code}
          buttonName="Back to Wallet"
          onClickButton={props.handleClose}
        />
        <div className="w-full lg:w-[294px] ">
          <TextField
            id="search-box"
            placeholder="Search in table"
            variant="outlined"
            className="w-full"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            // error={errors.amount ? true : false}
          />
        </div>
      </div>
      <div className="w-full lg:w-[920px] h-[230px] lg:h-[336px] overflow-y-auto">
        {historyTableData ? <TableComponent data={historyTableData} keys={historyTableKeys} /> : <TableSkeleton/>}
      </div>
    </div>
  );
};
export default TransactionHistory;
