import { useState, useRef, useEffect } from "react";
import { MenuItems } from "../../../constants/menuItems";
import Icon from "../icon";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useHistory } from "react-router-dom";

const MenuNavMobile = () => {
  const [selected, setSelected] = useState<string>("");
  const [openSubMenu, setOpenSubMenu] = useState<string | null>();
  const modalRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  useEffect(() => {
    const path = history.location.pathname.split("/")[1];
    // let finalPath = ''
    // path === 'my-fund' ? finalPath : finalPath = path
    setSelected(`/${path}`);
  }, [history.location.pathname]);

  const closeModal = () => {
    setOpenSubMenu(null);
  };

  useOutsideClick(modalRef, closeModal);

  return (
    <div className="w-full fixed bottom-0 flex p-2 items-center justify-center gap-2 bg-light-blue">
      {MenuItems.map((menuItem, index) => (
        <div
          key={index}
          className={`px-[18px] py-2.5 rounded-md relative ${
            selected === menuItem.address ||
            menuItem?.items?.some((item) => item.address === selected)
              ? "text-white bg-primary"
              : "text-primary bg-light-hover-blue"
          }`}
          onClick={() =>
            menuItem?.items
              ? setOpenSubMenu(menuItem.name)
              : (setSelected(menuItem.address), history.push(menuItem.address))
          }
        >
          <Icon icon={menuItem.icon} size={28} />
          {menuItem?.items && openSubMenu === menuItem.name && (
            <div
              ref={modalRef}
              className={`bg-white absolute bottom-16 ${
                index <= 2 ? "left-0" : index === 3 ? "left-20" : "right-0"
              } rounded-md border border-borders/50 shadow-md`}
            >
              <div className="relative px-8 py-6">
                <span
                  className={`absolute -bottom-3 ${
                    index <= 2 ? "left-4" : index === 3 ? "left-20" : "right-4"
                  } rounded-md z-10 w-6 h-6 transform rotate-45 border-b border-b-borders/50 border-r border-r-borders/50 bg-white`}
                ></span>
                <div className="flex flex-col divide-y divide-borders/50">
                  {menuItem?.items &&
                    menuItem.items.map((subItem, i) => (
                      <div
                        key={i}
                        className={`w-full flex gap-2 items-center p-2 ${
                          selected === subItem.address
                            ? "text-white bg-primary rounded-md"
                            : "text-text"
                        }  `}
                        onClick={() => {
                          setSelected(subItem.address);
                          history.push(subItem.address);
                        }}
                      >
                        <Icon icon={subItem.icon} size={32} />
                        <span className={`whitespace-nowrap`}>
                          {subItem.name}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MenuNavMobile;
