import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import Routes from './Routes';

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Routes />
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
