import { FunctionComponent, useState } from "react";
import RightTextButton from "../common/basics/rightTextButton";
import { ArrowDown, ArrowUp, FilterIcon, UploadIcon } from "../common/svgIcons";

interface IbPagesInnerLayoutProps {
  children?: React.ReactNode;
  buttonName?: string;
  handleClickButton?: () => void;
  handleSwitchFilters?: () => void;
}

const IbPagesInnerLayout: FunctionComponent<IbPagesInnerLayoutProps> = (
  props
) => {
  const [openFilterNav, setOpenFilterNav] = useState<boolean>(false);

  const handleClickFilters = () => {
    props.handleSwitchFilters && props.handleSwitchFilters();
    setOpenFilterNav(!openFilterNav);
  };
  return (
    <div className="w-full md:max-w-fit flex flex-col items-center gap-2">
      <div className="w-full flex justify-end items-center gap-2">
        <RightTextButton
          widthFull={false}
          onClick={handleClickFilters}
          icon={<FilterIcon className="text-white" />}
          secondIcon={
            openFilterNav ? (
              <ArrowUp color="white" />
            ) : (
              <ArrowDown color="white" />
            )
          }
          bgColor="bg-darkBlue"
        />
        {props?.buttonName && (
          <RightTextButton
            widthFull={false}
            name={props.buttonName}
            onClick={props.handleClickButton}
            icon={<UploadIcon />}
          />
        )}
      </div>
      <div className="w-full lg:max-w-fit pb-28 lg:pb-16">{props?.children}</div>
    </div>
  );
};
export default IbPagesInnerLayout;
