import { useEffect, useState } from "react";
import AccountsCard, { AccountCardParops } from "./accountCard";
import EmptyResult from "./emptyResult";
import ModalComponent from "../../components/common/basics/modalcomponent";
import CreateNewAccount from "./createNewAccount";
import { BrokerageLogo, Plus } from "../../components/common/svgIcons";
import PagesInnerLayout from "../../components/layouts/pagesInnerLayout";
import CardSkeleton from "../../components/common/skeletons/cardSkeleton";

const DemoAccounts = () => {
  const [data, setData] = useState<AccountCardParops[] | undefined>();
  // const [Authenticated, setAuthenticated] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleAddNewDemoAccount = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    setTimeout(
      () =>
        setData([
          {
            name: "Demo Zero",
            code: "USD - 1001",
            price: 98000,
            time: "1:50",
            status: true,
            logo: <BrokerageLogo />,
          },
          {
            name: "Prop - Turbo",
            code: "USD - 1001",
            price: 14000,
            time: "1:10",
            status: true,
            logo: <BrokerageLogo />,
          },
          {
            name: "Standard Netting",
            code: "USD - 1001",
            price: 32000,
            time: "1:15",
            status: true,
            logo: <BrokerageLogo />,
          },
        ]),
      1000
    );
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <PagesInnerLayout
      title="Demo Accounts"
      buttonName="New Account"
      handleClickButton={handleAddNewDemoAccount}
      buttonIcon={<Plus />}
    >
      <div className="w-full flex flex-col justify-center items-center lg:items-start lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-2">
        {data ? (
          data.length > 0 ? (
            data.map((account, index) => (
              <div className="w-full md:w-fit lg:w-auto cursor-pointer">
                <AccountsCard
                  key={index}
                  name={account.name}
                  code={account.code}
                  price={account.price}
                  time={account.time}
                  status={account.status}
                  logo={account.logo}
                />
              </div>
            ))
          ) : (
            <EmptyResult reason="no-account" type="demo" />
          )
        ) : (
          [0, 0, 0, 0].map((emptyItem, index) => (
            <CardSkeleton key={`skeleton-card-${index}`} />
          ))
        )}
      </div>
      <ModalComponent open={openModal} onClose={handleClose}>
        <CreateNewAccount handleClose={handleClose} />
      </ModalComponent>
    </PagesInnerLayout>
  );
};

export default DemoAccounts;
