export const accountTableKeys = ['Open Time', 'Type', 'Lots', 'Symbol', 'Price', 'S/L', 'T/P', 'Gain', 'Current Price'];

export const accountTableTestData = [
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
]