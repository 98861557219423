import { useEffect, useState } from "react";
import { CalendarIcon, DownloadIcon } from "../../components/common/svgIcons";
import Icon from "../../components/common/icon";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import LineChartComponent, {
  LineChartProps,
} from "../../components/common/basics/lineChartComponent";
import LineChartSkeleton from "../../components/common/skeletons/lineChartSkeleton";

const BestWorstTradeComponent = () => {
  const [chartData, setChartData] = useState<LineChartProps["data"]>();
  const [dataKeys, setDataKeys] = useState<LineChartProps["dataKeys"]>();

  useEffect(() => {
    setTimeout(() => {
      setChartData([
        { name: "Jan", best_trade: 10, worst_trade: 15 },
        { name: "Feb", best_trade: 20, worst_trade: 18 },
        { name: "Mar", best_trade: 15, worst_trade: 16 },
        { name: "Apr", best_trade: 25, worst_trade: 22 },
        { name: "May", best_trade: 30, worst_trade: 28 },
        { name: "Jun", best_trade: 20, worst_trade: 24 },
        { name: "Jul", best_trade: 25, worst_trade: 26 },
        { name: "Aug", best_trade: 30, worst_trade: 28 },
        { name: "Sep", best_trade: 20, worst_trade: 24 },
        { name: "Oct", best_trade: 25, worst_trade: 26 },
        { name: "Nov", best_trade: 30, worst_trade: 28 },
        { name: "Dec", best_trade: 40, worst_trade: 35 },
      ]);
    }, 1000);
    setDataKeys([
      { name: "best_trade", color: "#0E2EE3" },
      { name: "worst_trade", color: "#FF5630" },
    ]);
  }, []);

  return (
    <div className="w-full lg:w-auto flex flex-col justify-start items-start gap-4 bg-white">
      <span className="text-2xl font-medium">Best / Worst Trade</span>
      <div className="w-full lg:w-auto flex flex-col justify-start items-start gap-6 p-6 rounded-md border border-borders/50 relative">
        <div className="flex justify-start gap-4">
          <DropDownComponent
            icon={<CalendarIcon />}
            name="period"
            selectList={testList}
            handleSelect={() => {}}
            placeHolder="priod"
            // widthFull={true}
            width="w-28 md:w-32"
          />
          <DropDownComponent
            name="chart_type"
            selectList={testList}
            handleSelect={() => {}}
            // widthFull={true}
            placeHolder="chart type"
            width="w-28 md:w-32"
          />
          <div className="p-3 z-10 rounded-md bg-darkBlue text-center">
            <Icon icon={<DownloadIcon />} size={20} />
          </div>
        </div>
        {chartData ? (
          dataKeys && (
            <LineChartComponent data={chartData} dataKeys={dataKeys} />
          )
        ) : (
          <LineChartSkeleton />
        )}
      </div>
    </div>
  );
};
export default BestWorstTradeComponent;
