import { IconButton, Typography } from "@mui/material";
import { Arabic, English, Farsi, Turkish } from "../components/common/svgIcons";

export const languageOptions = [
  {
    value: "English",
    icon: <English />,
  },
  {
    value: "Arabic",
    icon: <Arabic />,
  },
  {
    value: "Turkish",
    icon: <Turkish />,
  },
  {
    value: "Persian",
    icon: <Farsi />,
  },
];
