import { UseFormRegister } from "react-hook-form";
import { FunctionComponent } from "react";
import { IFormInputs } from "./walletBaseComponent";
import CryptoPayWithdrawComponent from "./cryptoPayWithdraComponent";
import BankWireDepositForm from "./bankWireDepositForm";
interface ChildComponentProps {
  register: UseFormRegister<IFormInputs>;
  method: string;
  cancle: () => void;
}

const WithdrawComponent: FunctionComponent<ChildComponentProps> = ({
  register,
  method,
  cancle,
}) => {
  return (
    <div className="w-full flex flex-col justify-center gap-6">
      {method === "10" && <BankWireDepositForm register={register} cancel={cancle} />}
      {method === "20" && <CryptoPayWithdrawComponent />}
    </div>
  );
};

export default WithdrawComponent;
