import { useState } from "react";
import ChangePassword from "../../components/common/basics/changePassword";
import InputComponent from "../../components/common/basics/inputComponent";
import ModalComponent from "../../components/common/basics/modalcomponent";
import PhoneComponent from "../../components/common/basics/phoneComponent";
import RightTextButton from "../../components/common/basics/rightTextButton";
import {
  ChangeIcon,
  CorrectionMark,
  Email,
  Plus,
  UserIcon,
} from "../../components/common/svgIcons";
import ConfigureAuthApp from "./configureAuthApp";
import VerificationCard from "./verificationCard";
import UserAgreement from "./userAgreemnet";

const Profile = () => {
  const [openModal, setOpenModal] = useState<string | null>(null);

  const handleClose = () => {
    setOpenModal(null);
  };

  return (
    <div className="w-full flex flex-col gap-2 relative justify-start items-center lg:justify-start md:items-start">
      <div className="w-full text-left">
      <span className="text-primary text-xl font-medium">
        Complete your verification.
      </span>
      </div>
      <VerificationCard />
      <div className="w-full md:max-w-fit pb-28 lg:pb-16 flex flex-col justify-start items-start gap-6 border borde-borders/50 rounded-md p-6">
        <span className="text-2xl font-medium">My Information</span>
        <div className="flex flex-col md:flex-row items-start gap-6">
          <InputComponent
            title="Username"
            name="username"
            icon={<UserIcon />}
            type="text"
            disabled={true}
            widthFull
          />
          <div className="flex flex-col gap-6 justify-center items-end">
            <PhoneComponent
              handleChange={(val) => {}}
              // error={null}
              widthFull
              className="min-w-[220px]"
            />
            <RightTextButton
              name="Change Phone Number"
              icon={<ChangeIcon />}
              widthFull={false}
              // onClick={() => history.push("/verification")}
              bgColor="bg-darkBlue"
            />
          </div>
          <div className="flex flex-col gap-6 justify-center items-end">
            <InputComponent
              title="Email Address"
              name="email"
              icon={<Email />}
              type="text"
              disabled={true}
              widthFull
              className="min-w-[220px]"
            />
            <RightTextButton
              name="Change Phone Number"
              icon={<CorrectionMark />}
              widthFull={false}
              // onClick={() => history.push("/verification")}
            />
          </div>
        </div>
        <hr className="w-full bg-borders/50 flex-grow h-0.5" />
        <span className="text-2xl font-medium">Change Password</span>
        <ChangePassword />
        <hr className="w-full bg-borders/50 flex-grow h-0.5" />
        <span className="text-2xl font-medium">
          Two-Factor Authentication (2FA)
        </span>
        <RightTextButton
          name="Add Authenticator App"
          icon={<Plus />}
          className="my-3"
          onClick={() => setOpenModal("configure_app")}
        />
      </div>
      <ModalComponent
        open={openModal === "configure_app"}
        onClose={handleClose}
      >
        <ConfigureAuthApp
          handleClose={handleClose}
          verified={() => setOpenModal("user_agreement")}
        />
      </ModalComponent>
      <ModalComponent
        open={openModal === "user_agreement"}
        onClose={handleClose}
      >
        <UserAgreement handleClose={handleClose} />
      </ModalComponent>
    </div>
  );
};

export default Profile;
