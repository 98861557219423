import { Box, Modal } from "@mui/material";
import React, { forwardRef } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: window.innerWidth <= 768 ? "0" : "50%",
  transform:
    window.innerWidth <= 768 ? "translate(0, -50%)" : "translate(-50%, -50%)",
  width: window.innerWidth <= 768 ? "100%" : "auto",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 700,
  bgcolor: "background.paper",
  border: "1px solid rgba(163, 163, 163, 0.5)", // Color #A3A3A3 with 50% opacity
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex", // Add this line
  flexDirection: "column", // Add this line
};

// if (window.innerWidth <= 768) { // Assuming mobile view is for screens up to 768px wide
//   style.width = 400;
// }

interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
}

const ModalComponent = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="w-full h-full border border-borders/50 rounded"
      sx={{
        '@media (max-width: 768px)': {
          '& .css-8dhb66': {
            padding: 1,
          },
        },
      }}
    >
      <Box sx={style}>{props.children}</Box>
    </Modal>
  );
});

export default ModalComponent;
