import { useEffect, useState } from "react";
import DoughnutChartComponent, {
  DonutChartProps,
} from "../../components/common/basics/doughnutChart";
import { DownloadIcon } from "../../components/common/svgIcons";
import Icon from "../../components/common/icon";
import DoughnutChartSkeleton from "../../components/common/skeletons/doughnutChartSkeleton";

const VolumeAllocationComponent = () => {
  const [chartData, setChartData] = useState<DonutChartProps["chartData"]>();

  useEffect(() => {
    setTimeout(() => {
      setChartData([
        { name: "USDCAD", value: 19, color: "#060E38", strokeWidth: 0 },
        { name: "EURUSD", value: 32, color: "#0E2EE3", strokeWidth: 0 },
        { name: "SOLUSD", value: 29, color: "#FF5630", strokeWidth: 10 },
        { name: "BTCUSD", value: 12, color: "#748692", strokeWidth: 0 },
      ]);
    }, 1000);
  }, []);

  return (
    <div className="w-full lg:w-auto flex flex-col justify-start items-start gap-4 bg-white">
      <span className="text-2xl font-medium">Volume Allocation</span>
      {chartData ? <div className="w-full flex flex-col justify-start items-center gap-6 p-6 rounded-md border border-borders/50 relative">
        <div className="absolute top-6 right-6 p-3 z-10 rounded-md bg-darkBlue text-center">
          <Icon icon={<DownloadIcon />} size={20} />
        </div>
        <DoughnutChartComponent
          chartData={chartData}
          onChangeData={(newData) => setChartData(newData)}
        />
        <div className="w-full flex flex-col justify-center items-center gap-2">
          {chartData.map((chartItem, index) => (
            <div
              className={`w-full relative flex justify-between items-center gap-4 pl-4 pr-2 py-2.5 border-l-2 border-l-[${chartItem.color}]`}
              key={index}
            >
              <div
                id="star"
                className={`h-full rounded-md absolute left-0 top-0 w-2`}
                style={{ backgroundColor: chartItem.color }}
              />
              <span className="text-text text-base font-normal">
                {chartItem.name}
              </span>
              <span className="text-text text-base font-semibold">
                {chartItem.value}
              </span>
            </div>
          ))}
        </div>
      </div> : <DoughnutChartSkeleton />}
    </div>
  );
};
export default VolumeAllocationComponent;
