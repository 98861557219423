import { useState } from "react";
import { UploadIcon } from "../svgIcons";
import Icon from "../icon";

const UploadBox = () => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const imageUrl = URL.createObjectURL(file);
    setImagePreview(imageUrl);
  };

  return (
    <div className="w-full h-36 lg:w-80 flex flex-col items-center justify-end gap-1 py-4 rounded-md border-dashed border-2 border-borders/50 ">
      {imagePreview && selectedFile && (
          <div className="flex gap-1 justify-center items-center">
            <img src={imagePreview} alt="Preview" style={{ width: "50px" }} />
            <div className="flex flex-col gap-1">
            <span className="text-text text-xs font-light ">size: {selectedFile.size}</span>
            <span className="text-text text-xs font-light ">name: {selectedFile.name}</span>
            </div>
          </div>
        )}
      <span className="text-text text-xs font-normal">
        Drag and drop file here or:
      </span>

      <div className="flex flex-col justify-center items-center gap-1">
        

        <label htmlFor="file-upload" className="cursor-pointer">
          <div
            className={`w-full h-12 rounded-md flex items-center gap-2 pt-6 pl-6 pr-2 pb-2 bg-primary hover:bg-secondary active:bg-darkBlue`}
          >
            <Icon icon={<UploadIcon />} size={20} />
            <span
              className={`text-white
                 text-xs font-normal `}
            >
              Click to upload
            </span>
          </div>
        </label>
        <input
          id="file-upload"
          type="file"
          className="hidden"
          //   style={{ display: "none" }}
          onChange={handleFileChange}
        />
        {/* {selectedFile && <p>Selected file: {selectedFile.name}</p>} */}
      </div>
    </div>
  );
};
export default UploadBox;
