import {
  CalendarIcon,
  CorrectionMark,
  GlobeIcon,
  MethodIcon,
  UserIcon,
} from "../../components/common/svgIcons";
import { FunctionComponent, useState } from "react";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import InputComponent from "../../components/common/basics/inputComponent";
import RightTextButton from "../../components/common/basics/rightTextButton";
import BorderedButton from "../../components/common/basics/borderedButton";
import UploadBox from "../../components/common/basics/uploadBox";
import DatePickerComponent from "../../components/common/basics/datePickerComponent";

interface IdentificationDocPropsInterface{
    handelNextStep: () => void;
    handelLastStep: () => void;
}

const IdentificationDoc: FunctionComponent<IdentificationDocPropsInterface> = ({handelNextStep, handelLastStep}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-6">
      <DropDownComponent
        title="Document Type"
        name="document_type"
        icon={<MethodIcon />}
        selectList={testList}
        handleSelect={() => {}}
        widthFull

      />
      <DropDownComponent
        title="Country of Issuance"
        name="country"
        icon={<GlobeIcon />}
        selectList={testList}
        handleSelect={() => {}}
        widthFull

      />
      <InputComponent
        title="Identification Number"
        name="identication_number"
        icon={<UserIcon />}
        handleChange={() => {}}
        type="text"
      />
      <DatePickerComponent
        title="Expiry Date"
        name="expiry_date"
        icon={<CalendarIcon />}
        handleChange={() => {}}
        widthFull
        type="text"
      />
      <div className="flex flex-col gap-1">
        <span className="text-darkBlue text-sm font-medium">Document File</span>
        <UploadBox />
        <span className="text-grayBg text-xs font-normal">Max size: 1 Mb</span>
      </div>
      <hr className="w-full col-span-2 bg-borders/50 h-0.5" />
      <div className="flex gap-2 p-4 w-full md:w-72">
      <BorderedButton name="back" className="py-2 px-2" onClick={handelLastStep} />
        <RightTextButton
          name="save"
          icon={<CorrectionMark />}
          onClick={handelNextStep}
        />
      </div>
    </div>
  );
};
export default IdentificationDoc;
