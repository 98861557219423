import { FunctionComponent, useState } from "react";
import Icon from "../icon";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Password } from "../svgIcons";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface PasswordComponentPropsInterface {
  title?: string;
  handleChange?: (e: any) => void;
  error: boolean;
  widthFull?: boolean;
}

const PasswordComponent: FunctionComponent<PasswordComponentPropsInterface> = ({
  handleChange,
  error,
  widthFull,
  title
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className="w-full flex flex-col gap-1">
      <span className="text-text text-sm font-medium ml-9 whitespace-nowrap">{title || "Password"}</span>
      <div className="w-full flex items-center gap-2">
        <Icon icon={<Password />} size={24} />
        <div className={`w-full ${widthFull ? "w-full" : "lg:w-[294px]"}`}>
          <FormControl
            sx={{ m: 1, minWidth: 67, margin: 0 }}
            variant="outlined"
            className="w-full"
          >
            <OutlinedInput
              sx={{ height: "44px" }}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              error={error ? true : false}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};
export default PasswordComponent;
