export const historyTableKeys = ['Transaction Type', 'Payment Display Name', 'Request Time', 'Amount', 'Currency', 'Account', 'Result'];

export const colorObject: { [key: string]: string }  = {
    Denied: "bg-error",
    Approved: "bg-primary",
    Pending: "bg-borders"
}

export const historyTableTestData = [
    {
        transaction_type: "Wallet Deposit",
        payment_display_name: "Bank Wire Deposit",
        request_time: "1/3/2024 3:17:07 PM",
        amount: 132,
        currency: "EURO",
        account: 254522431,
        result: "Denied",
    },
    {
        transaction_type: "Wallet Deposit",
        payment_display_name: "Bank Wire Deposit",
        request_time: "1/3/2024 3:17:07 PM",
        amount: 132,
        currency: "EURO",
        account: 254522431,
        result: "Approved",
    },
    {
        transaction_type: "Wallet Deposit",
        payment_display_name: "Bank Wire Deposit",
        request_time: "1/3/2024 3:17:07 PM",
        amount: 132,
        currency: "EURO",
        account: 254522431,
        result: "Pending",
    },
    {
        transaction_type: "Wallet Deposit",
        payment_display_name: "Bank Wire Deposit",
        request_time: "1/3/2024 3:17:07 PM",
        amount: 132,
        currency: "EURO",
        account: 254522431,
        result: "Denied",
    },
    {
        transaction_type: "Wallet Deposit",
        payment_display_name: "Bank Wire Deposit",
        request_time: "1/3/2024 3:17:07 PM",
        amount: 132,
        currency: "EURO",
        account: 254522431,
        result: "Denied",
    },
    {
        transaction_type: "Wallet Deposit",
        payment_display_name: "Bank Wire Deposit",
        request_time: "1/3/2024 3:17:07 PM",
        amount: 132,
        currency: "EURO",
        account: 254522431,
        result: "Denied",
    },
    {
        transaction_type: "Wallet Deposit",
        payment_display_name: "Bank Wire Deposit",
        request_time: "1/3/2024 3:17:07 PM",
        amount: 132,
        currency: "EURO",
        account: 254522431,
        result: "Denied",
    },
    {
        transaction_type: "Wallet Deposit",
        payment_display_name: "Bank Wire Deposit",
        request_time: "1/3/2024 3:17:07 PM",
        amount: 132,
        currency: "EURO",
        account: 254522431,
        result: "Denied",
    },
    {
        transaction_type: "Wallet Deposit",
        payment_display_name: "Bank Wire Deposit",
        request_time: "1/3/2024 3:17:07 PM",
        amount: 132,
        currency: "EURO",
        account: 254522431,
        result: "Denied",
    },

]