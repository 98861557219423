import { FunctionComponent } from "react";
import Icon from "../icon";

interface ChildProps {
  name?: string;
  onClick?: () => void;
  className?: string;
  icon?: JSX.Element;
  secondIcon?: JSX.Element;
  widthFull?: boolean;
  bgColor?: string;
  textColor?: string;
}

const RightTextButton: FunctionComponent<ChildProps> = (props) => {
  return (
    <div
      className={`${props.widthFull ? "w-full" : "w-auto"} rounded-md flex items-center justify-end cursor-pointer gap-2 pt-6 pl-6 pr-2 pb-2 ${
        props.bgColor ? `${props.bgColor} active:bg-primary` : "bg-primary hover:bg-secondary active:bg-darkBlue"
      } ${props.className}`}
      onClick={props.onClick}
    >
      {props.icon && <Icon icon={props.icon} size={20} />}
      {props.secondIcon && <Icon icon={props.secondIcon} size={20} />}
      <span
        className={`text-${
          props.textColor ? props.textColor : "white"
        } text-xs font-normal whitespace-nowrap `}
      >
        {props.name}
      </span>
    </div>
  );
};

export default RightTextButton;
