import { FunctionComponent } from "react";
import Icon from "../../components/common/icon";

export interface AccountCardParops {
  name: string;
  code: string;
  price: number;
  time: string;
  status: boolean;
  logo: JSX.Element;
  id?: number;
  isOpen?: boolean;
  margin?: number;
  equity?: number;
  credit?: number;
  free_margin?: number;
}
const AccountsCard: FunctionComponent<AccountCardParops> = (props) => {
  return (
    <div className="w-full flex flex-col gap-2 items-center justify-center p-2 border border-borders/50 rounded-md relative">
      <Icon icon={props.logo} size={162} />
      <div className="w-full flex justify-between items-start md:gap-20 lg:gap-2 p-6 bg-grayBg/10 rounded-md">
        <div className="flex flex-col items-start gap-1">
          <span className="text-text text-xl xl:text-2xl font-semibold">{props.name}</span>
          <span className="text-text text-lg font-normal">{props.code}</span>
          <div className="text-center bg-primary rounded-md px-1">
            <span className="text-white text-sm font-normal">
              {props.status ? "Approved" : "Disapproved"}
            </span>
          </div>
        </div>
        <div className="flex flex-col items-end gap-1">
          <span className="text-text text-lg font-semibold">
            ${props.price?.toLocaleString("en-US", { maximumFractionDigits: 0 })}
          </span>
          <span className="text-primary text-lg font-semibold">
            {props.time}
          </span>
        </div>
      </div>
      {props.isOpen && (
        <div className="w-full flex justify-between gap-6 py-2 px-6 bg-grayBg/10 rounded-md">
          <div className="w-full flex flex-col divide-y divide-borders/50">
            <div className="w-full flex justify-between py-4 items-center">
              <span className="text-text text-base font-normal">Margin</span>
              <span className="text-text text-base font-normal">
                {props.margin}
              </span>
            </div>
            <div className="w-full flex justify-between py-4 items-center">
              <span className="text-text text-base font-normal">Equity</span>
              <span className="text-text text-base font-normal">
                {props.equity}
              </span>
            </div>
          </div>
          <div className="w-full flex flex-col divide-y divide-borders/50">
            <div className="w-full flex justify-between py-4 items-center">
              <span className="text-text text-base font-normal">Credit</span>
              <span className="text-text text-base font-normal">
                {props.credit}
              </span>
            </div>
            <div className="w-full flex justify-between py-4 items-center">
              <span className="text-text text-base font-normal">
                Free Margin
              </span>
              <span className="text-text text-base font-normal">
                {props.free_margin}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AccountsCard;
