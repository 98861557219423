import { InputAdornment, TextField } from "@mui/material";
import { FunctionComponent } from "react";
import { SearchIcon } from "../common/svgIcons";

interface IbLayoutPropsInterface {
  title: string;
  children: React.ReactNode;
  smallTitle?: string;
  openFilterNav: boolean;
  largeFilter?: boolean;
}

const IbLayout: FunctionComponent<IbLayoutPropsInterface> = (props) => {
  return (
    <div
      className={`w-full flex flex-col gap-6 lg:gap-6 xl:gap-8 justify-start items-start ${
        props.largeFilter
          ? "xl:flex-row xl:items-center xl:justify-between"
          : "md:flex-row md:items-center md:justify-between"
      } rounded-md border border-borders/50 p-2 pt-6 mb-2`}
    >
      <div className="flex justify-start items-center gap-2">
        <div className="flex flex-col">
          <span className="text-xl md:text-2xl xl:text-xl 2xl:text-2xl font-medium whitespace-nowrap mb-[-10px]">
            ib-
          </span>
          <span className="md:hidden text-xl font-medium whitespace-nowrap">
            {props.smallTitle}
          </span>
          <span
            className={`${
              props.smallTitle ? "hidden md:flex" : "flex"
            } text-xl md:text-2xl xl:text-lg 2xl:text-2xl font-medium whitespace-nowrap`}
          >
            {props.title}
          </span>
        </div>
        <div className="w-full xl:min-w-[250px] flex justify-start items-start ">
          <TextField
            id="search-box"
            placeholder="Search in table"
            variant="outlined"
            className="w-full"
            InputProps={{
              style: { height: 44 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            // error={errors.amount ? true : false}
          />
        </div>
      </div>
      {props.openFilterNav && props.children}
    </div>
  );
};
export default IbLayout;
