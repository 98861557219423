import { Email, ProfileCircle } from "../../components/common/svgIcons";
import { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import SimpleButton from "../../components/common/basics/simpleButton";
import { useHistory } from "react-router-dom";
import InputComponent from "../../components/common/basics/inputComponent";
import PasswordComponent from "../../components/common/basics/passwordComponent";
import { passwordAnalyze } from "../../functions/passwordAnalyze";

interface IFormLoginInputs {
  email: string;
  password: string;
}

const LoginForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<IFormLoginInputs>({});

  const handleChangePassword: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const passwordValue = event.target.value;
    const analyze = passwordAnalyze({}, passwordValue);
    if (analyze) {
      clearErrors("password");
      setValue("password", passwordValue);
    } else {
      setError("password", {
        type: "manual",
        message: "Password is not completed",
      });
    }
  };

  const onSubmit: SubmitHandler<IFormLoginInputs> = async (data) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
    if (data.email === "test@gmail.com" && data.password === "Test123@") {
      history.push("/dashboard");
    }
  };

  return (
    <form
      className="w-full flex flex-col gap-3 justify-center items-center py-3"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full relative">
        <InputComponent
          name="email"
          title="Email Address"
          icon={<Email className="text-darkBlue" />}
          type="text"
          handleChange={(value) => setValue("email", value)}
          register={register}
          required
          error={errors.email ? true : false}
          widthFull={true}
        />
        <span
          className="text-error text-sm font-medium cursor-pointer absolute bottom-[-25px] right-0"
          onClick={() => history.push("/password-verification")}
        >
          Forget Password?
        </span>
      </div>
      <PasswordComponent
        handleChange={handleChangePassword}
        error={errors.password ? true : false}
        widthFull={true}
      />
      <SimpleButton
        name="Log in"
        icon={<ProfileCircle />}
        loading={loading}
        className="my-3"
      />
    </form>
  );
};

export default LoginForm;
