import {
  AccountIcon,
  BankIcon,
  CorrectionMark,
  Dollar,
  Hashtag,
  LocationIcon,
} from "../../components/common/svgIcons";
import UploadBox from "../../components/common/basics/uploadBox";
import { UseFormRegister } from "react-hook-form";
import { FunctionComponent, useState } from "react";
import { IFormInputs } from "./walletBaseComponent";
import BorderedButton from "../../components/common/basics/borderedButton";
import SubmitButton from "../../components/common/basics/submitButton";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";
import InputComponent from "../../components/common/basics/inputComponent";
interface ChildComponentProps {
  register: UseFormRegister<IFormInputs>;
  cancel: () => void;
}

const BankWireDepositForm: FunctionComponent<ChildComponentProps> = ({
  register,
  cancel,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmitDepositForm = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <div className="flex flex-col justify-center md:grid md:grid-cols-2 gap-6">
      <DropDownComponent
        name="bank"
        title="Bank to send funds"
        icon={<BankIcon className="text-darkBlue" />}
        selectList={testList}
        handleSelect={(value) => {}}
        required
        register={register}
        // error={errors.bank ? true : false}
        widthFull
      />
      <DropDownComponent
        name="currency"
        title="Choose Currency"
        icon={<Dollar className="text-darkBlue" />}
        selectList={testList}
        handleSelect={(value) => {}}
        required
        register={register}
        // error={errors.currency ? true : false}
        widthFull
      />
      <InputComponent
        name="amount"
        title="Amount in $"
        icon={<Dollar className="text-darkBlue" />}
        type="text"
        // handleChange={(value) => setValue("amount", value)}
        register={register}
        required
        // error={errors.amount ? true : false}
        widthFull={true}
      />
      <hr className="w-full col-span-2 bg-borders/50 flex-grow h-0.5" />
      <InputComponent
        name="beneficiary_name"
        title="Beneficiary Name"
        icon={<AccountIcon className="text-borders" />}
        disabled
        type="text"
        // handleChange={(value) => setValue("beneficiary_name", value)}
        register={register}
        // error={errors.beneficiary_name ? true : false}
        widthFull={true}
      />
      <InputComponent
        name="address"
        title="Address"
        icon={<LocationIcon className="text-borders" />}
        disabled
        type="text"
        // handleChange={(value) => setValue("address", value)}
        register={register}
        // error={errors.address ? true : false}
        widthFull={true}
      />
      <InputComponent
        name="account_number"
        title="Account Number"
        icon={<Hashtag className="text-borders" />}
        disabled
        type="text"
        // handleChange={(value) => setValue("account_number", value)}
        register={register}
        // error={errors.account_number ? true : false}
        widthFull={true}
      />
      <InputComponent
        name="swift_code"
        title="Swift/IFSC Code"
        icon={<Hashtag className="text-borders" />}
        disabled
        type="text"
        // handleChange={(value) => setValue("swift_code", value)}
        register={register}
        // error={errors.swift_code ? true : false}
        widthFull={true}
      />
      <InputComponent
        name="iban"
        title="IBAN"
        icon={<Hashtag className="text-borders" />}
        disabled
        type="text"
        // handleChange={(value) => setValue("iban", value)}
        register={register}
        // error={errors.iban ? true : false}
        widthFull={true}
      />
      <hr className="w-full col-span-2 bg-borders/50 flex-grow h-0.5" />
      <div className="flex flex-col gap-1">
        <span className="text-darkBlue text-sm font-medium">tt Copy </span>
        <UploadBox />
        <span className="text-grayBg text-xs font-normal">Max size: 1 Mb</span>
      </div>
      <hr className="w-full col-span-2 bg-borders/50 flex-grow h-0.5" />
      <div className="flex gap-2 p-4 w-full md:w-72">
        <BorderedButton name="Cancel" onClick={cancel} />
        <SubmitButton
          widthFull={true}
          loading={loading}
          name="Submit Deposit"
          icon={<CorrectionMark />}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

export default BankWireDepositForm;
