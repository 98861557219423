import RightTextButton from "../../components/common/basics/rightTextButton";
import {
  AccountLogo,
  CheckMark,
  Currency,
  Hashtag,
} from "../../components/common/svgIcons";
import { useForm } from "react-hook-form";
import ModalHeader from "../../components/common/basics/modalHeader";
import { FunctionComponent } from "react";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";
import { testList } from "../../constants/testList";

interface IFormInputs {
  account_type: string;
  currency_type: string;
  leverage: string;
}

interface CreateNewAccountProps {
  handleClose: () => void;
}

const CreateNewAccount: FunctionComponent<CreateNewAccountProps> = (props) => {
  const {
    register,
    formState: { errors },
  } = useForm<IFormInputs>({});

  return (
    <div className="w-full flex flex-col gap-4 p-2 bg-white relative">
      <ModalHeader
        title="Create New Account"
        buttonName="Back to Accounts"
        onClickButton={props.handleClose}
      />
      <form className="w-full flex flex-col justify-center lg:grid lg:grid-cols-2 justify-items-start items-center gap-2">
        <DropDownComponent
          name="account_type"
          title="Account Type"
          icon={<AccountLogo className="text-darkBlue" />}
          selectList={testList}
          handleSelect={() => {}}
          register={register}
          required
          error={errors.account_type ? true : false}
          width="w-full lg:w-[294px]"
        />
        <DropDownComponent
          name="currency_type"
          title="Choose Currency"
          icon={<Currency className="text-darkBlue" />}
          selectList={testList}
          handleSelect={() => {}}
          register={register}
          required
          error={errors.currency_type ? true : false}
          width="w-full lg:w-[294px]"
        />
        <DropDownComponent
          name="leverage"
          title="Leverage"
          icon={<Hashtag className="text-darkBlue" />}
          selectList={testList}
          handleSelect={() => {}}
          register={register}
          required
          error={errors.leverage ? true : false}
          width="w-full lg:w-[294px]"
        />
        <div className="w-full flex gap-2 col-span-2 mt-4">
          <RightTextButton
            name="Cancel"
            bgColor="white"
            textColor="primary"
            className="bg-white border border-borders/50 text-primary"
          />
          <RightTextButton name="Create Account" icon={<CheckMark />} />
        </div>
      </form>
    </div>
  );
};
export default CreateNewAccount;
