import Icon from "../../components/common/icon";
import {
  AccountIcon,
  DepositLogo,
  MethodIcon,
  WithdrawLogo,
} from "../../components/common/svgIcons";
import { SubmitHandler, useForm } from "react-hook-form";
import { FunctionComponent, useState } from "react";
import DepositComponent from "./depositComponent";
import WithdrawComponent from "./withdrawComponent";
import { testList } from "../../constants/testList";
import DropDownComponent from "../../components/common/basics/dropDpwnComponent";

export interface IFormInputs {
  account: string;
  method: string;
  bank: string;
  currency: string;
  amount: number;
  beneficiary_name: string;
  address: string;
  account_number: number;
  swift_code: number;
  iban: string;
}

interface ChildComponentProps {
  selected: string;
}

const WalletBaseComponent: FunctionComponent<ChildComponentProps> = ({
  selected,
}) => {
  const {
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<IFormInputs>({});

  const [method, account] = watch(['method', 'account']);
  const methodList = [
    {
      name: "Bank Wire Deposit",
      value: 10,
    },
    {
      name: "Crypto Pay",
      value: 20,
    },
  ];

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {};

  return (
    <div className="w-full lg:w-auto flex flex-col gap-2">
      <div className="w-full flex flex-col justify-center items-center">
        <Icon
          icon={selected === "Deposit" ? <DepositLogo /> : <WithdrawLogo />}
          size={64}
        />
        <span className="text-darkBlue text-xl font-medium">
          Make your first {selected}!
        </span>
      </div>
      <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-center md:grid md:grid-cols-2 gap-6">
          <DropDownComponent
            name="account"
            title={`Select Account to ${selected}`}
            icon={<AccountIcon className="text-darkBlue" />}
            selectList={testList}
            value={account || ""}
            handleSelect={(value) => setValue("account", value.toString())}
            register={register}
            required
            error={errors.account ? true : false}
            width="w-full lg:w-[294px]"
          />
          <DropDownComponent
            name="method"
            title={`${selected} Method`}
            icon={<MethodIcon className="text-darkBlue" />}
            selectList={methodList}
            value={method || ""}
            handleSelect={(value) => setValue("method", value.toString())}
            register={register}
            required
            error={errors.method ? true : false}
            width="w-full lg:w-[294px]"
          />
        </div>
        {(account && method) && (
          <div className="flex flex-col gap-6 justify-center">
            {selected === "Deposit" && (
              <DepositComponent
                register={register}
                method={method}
                cancle={() => (reset())}
              />
            )}
            {selected === "Withdraw" && (
              <WithdrawComponent
                register={register}
                method={method}
                cancle={() => (reset())}
              />
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default WalletBaseComponent;
