import { Skeleton } from "@mui/material";

const LineChartSkeleton = () => {
  return (
    <div className="w-full flex flex-col justify-start items-end gap-2 mt-12">
      <Skeleton variant="rounded" width={391} height={291} />
      <div className="w-full flex justify-center gap-12">
        <Skeleton variant="rounded" width={34} height={24} />
        <Skeleton variant="rounded" width={34} height={24} />
      </div>
    </div>
  );
};
export default LineChartSkeleton;
