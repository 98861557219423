export const ibClientsTableKeys = ['Client IB Range', 'Client Name', 'Phone Number', 'Country ', 'Creation Date', 'Active'];
export const ibLeadsTableKeys = ['Name', 'Country', 'City', 'Address ', 'Phone Number', 'Is Converted', 'Creation Date'];
export const accountStatementsTableKeys = ['IB Code', 'Wallet', 'Commissions', 'Volume'];
export const ibSubIbAccountTableKeys = ['IB Code', 'Wallet', 'Commissions', 'Volume'];
export const ibCpaReportsTableKeys = ['Time', 'Acc#', 'Ticket', 'Type', 'Amount', 'Commissions', 'Balance'];
export const ibBreakDownReportTableKeys = ['Account', 'Lots', 'Number of Trades', 'Total Deposit', 'Total Withdrawal', 'Realized Swap', 'Realized Commission', 'Realized Profit', 'Unrealized Profit C/Forward', 'Unrealized Profit B/Forward', 'Unrealized Net Profit', 'Profit Adjustments', 'Closing Balance', 'Starting Equity', 'Closing Equity', 'Closing Credit', 'Real Profit', 'Net Revenue', 'Total Sales Commission'];

export const ibClientsTableData = [
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
    {
        open_time: "1/3/2024 3:17:07 PM",
        type: "",
        lots: 1,
        symbol: "XAU/USD",
        price: 1000000,
        s_l: 1000000,
        t_p: 1000000,
        gain: 1000000,
        current_price: 1000000,
    },
]